.footer {
  background: $clr-stratos;
  @include karla(400, rem(16));
  color: $clr-white;
  line-height: rem(26);
  padding: rem(40) 0;
}

.footer__section {
  width: 22%;
}

.footer__section--main {
  width: 25%;
}

.footer__main-title {
  @include rubik(700, rem(28));
}

.footer__subtitle {
  @include rubik(700, rem(16));
}

.footer__main-title,
.footer__subtitle {
  text-transform: uppercase;
  line-height: rem(50);
  margin-top: 0;
}

.footer__label {
  width: rem(60);
  height: auto;
}

.footer__meta {
  background-color: $clr-white;
  @include karla(400, rem(14));
  color: $clr-stratos;
  padding: rem(20) 0;
}

.footer__contact-trigger {
  color: $clr-flamingo;
}

.footer__menu {
  list-style: none;
  padding: 0;

  a {
    @include transition(300ms);

    &:hover {
      color: $clr-flamingo;
    }
  }
}
