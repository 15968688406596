.login {
  background-color: $clr-alabaster;
  padding: rem(80) 0;
  @include karla(400, rem(16));
  flex-grow: 1;

  label {
    display: inline-block;
  }

  input {
    width: 100%;
  }

  input[type=submit],
  input[type=checkbox] {
    width: auto;
  }

  a {
    display: block;
    color: $clr-stratos;
    margin-top: rem(60);
  }

  .description {
    display: none;
  }
}

.login__header {
  border-bottom: 1px solid #c3c3c3;
  padding-bottom: rem(30);
}

.login__sub-title {
  @include rubik(500, rem(24));
  color: $clr-stratos;
}

.login__half {
  width: 45%;
}
