#Contactpersoon,
#deliveryTimeSlotGroup,
#Form_Cart_paymentMethod_Holder {
  input[type=radio] {
    position: absolute;
    visibility: hidden;
  }

  label {
    cursor: pointer;
    display: flex;
    align-items: center;
  }

  input[type=radio]+.error+label:before {
    content: ' ';
    display: block;
    background-image: url('../images/icons/radiobutton-initial.svg');
    background-repeat: no-repeat;
    background-size: 100%;
    width: rem(30);
    min-width: rem(30);
    height: rem(30);
    margin-right: rem(20);
  }

  input[type=radio]+label:before {
    content: ' ';
    display: block;
    background-image: url('../images/icons/radiobutton-initial.svg');
    background-repeat: no-repeat;
    background-size: 100%;
    width: rem(30);
    min-width: rem(30);
    height: rem(30);
    margin-right: rem(20);
  }

  input[type=radio]:checked+label:before {
    content: '';
    background-image: url('../images/icons/radiobutton-checked.svg');
  }

  input[type=radio]:checked+.error+label:before {
    content: '';
    background-image: url('../images/icons/radiobutton-checked.svg');
  }

  input[type=radio]+label.error {
    &:before {
      content: ' ';
      display: none;
    }
  }
}
