.l-wrapper {
  width: 90%;
  max-width: em(1160);
  margin: 0 auto;
}

.l-wrapper--small {
  width: 90%;
  max-width: em(550);
  margin: 0 auto;
}

.l-wrapper--medium {
  width: 90%;
  max-width: em(960);
  margin: 0 auto;
}

.l-wrapper--large {
  width: 90%;
  max-width: em(1440);
  margin: 0 auto;
}

.l-wrapper--full-width {
  width: 100%;
}

.l-wrapper--contact {
  width: 90%;
  max-width: rem(820);
  margin: 0 auto;
}
