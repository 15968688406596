.btn {
  cursor: pointer;
  display: inline-block;
  @include rubik(500, rem(14));
  @include borderRadius(6px);
  padding: rem(15) rem(20);
  border: 3px solid transparent;
  @include transition(300ms);
}

.btn--shadow {
  @include shadow(0, 0, 15px, 0.25);
}

.btn--orange-fill {
  background: $clr-flamingo;
  color: $clr-white;
  border-color: $clr-flamingo;

  &:hover {
    background: transparent;
    color: $clr-flamingo;
  }
}

.btn--blue-fill {
  background: $clr-stratos;
  color: $clr-white;
  border-color: $clr-stratos;

  &:hover {
    background: transparent;
    color: $clr-stratos;
  }
}

.btn--orange-stroke {
  background: transparent;
  border-color: $clr-flamingo;
  color: $clr-flamingo;

  &:hover {
    background: $clr-flamingo;
    color: $clr-white;
  }
}

.btn-underline {
  @include rubik(700, rem(14));
  text-decoration: underline;
}

.btn__plus {
  display: inline-block;
  font-weight: 400;
  margin: 0 0 0 rem(10);
  transform: scale(1.5);
}
