@media screen and (max-width: rem(960)) {

  .banner__divider,
  .banner__bus {
    width: 100%;
  }

  .banner__bus {
    img {
      display: block;
      margin: 0 auto;
      width: 70%;
      max-width: rem(600);
    }
  }

  .banner__text {
    justify-content: center;
  }

  .banner__content {
    max-width: initial;
    padding-bottom: 0;
  }

  .banner__image {
    margin-top: rem(40);
    padding-top: 45%;
  }

  .products__item {
    width: 90%;
    max-width: initial;
    margin-bottom: rem(50);
  }

  .cta-calculating__content {
    text-align: center;
    width: 100%;
  }

  .cta-calculating__image {
    display: block;
    width: 90%;
    margin: rem(100) auto 0;
  }

  .product-info {
    padding-bottom: rem(40);
  }

  .product__content {
    font-size: rem(16);
    line-height: rem(24);
  }

  .product-info__image,
  .product-info__content {
    width: 100%;

    img {
      display: block;
      max-width: rem(320);
    }
  }

  .product-info__content {
    margin-top: rem(40);
    text-align: center;
  }

  .cart-proces {

    .form-wrapper__left,
    .form-wrapper__right {
      width: 100%;
    }

    .form-wrapper__right {
      display: flex;
      flex-direction: column;

      .cart-proces__total {
        order: 5;
        margin-top: 0;
        margin-bottom: rem(30);
      }

      .shippingCalanderTitle {
        order: 1;
        margin-top: 0;
      }

      #Form_Cart_ShippingCalendar_Holder {
        order: 2;
        margin-bottom: 0;
      }

      #deliveryTimeSlotGroup {
        order: 3;
      }

      #Form_Cart_paymentMethod_Holder {
        order: 4;
        margin-bottom: rem(30);
      }
    }
  }

  .selling-points {
    flex-wrap: wrap;
  }

  .selling-points__map {
    width: 100%;
    height: rem(600);
  }

  .selling-points__content {
    width: 100%;
    padding: rem(40) 5% rem(70);
  }

  .selling-points__cta-link {
    width: rem(280);
    margin-left: rem(-140);

    .cta-link__title {
      @include rubik(700, rem(16));
    }

    .cta-link__text {
      @include karla(400, rem(12));
    }
  }

  .footer__section,
  .footer__section--main {
    width: 48%;
    margin-bottom: rem(20);
  }
}
