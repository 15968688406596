#Form_Cart_OneAddressForEverything_Holder {
  input[type=checkbox] {
    position: absolute;
    visibility: hidden;
  }

  label {
    cursor: pointer;
    display: flex;
    align-items: center;
  }

  input[type=checkbox]+label:before {
    content: ' ';
    display: block;
    background-image: url('../images/icons/checkbox-initial.svg');
    width: rem(30);
    height: rem(30);
    margin-right: rem(20);
  }

  input[type=checkbox]:checked+label:before {
    content: '';
    background-image: url('../images/icons/checkbox-checked.svg');
  }
}

#CallMeGroup {
  width: 100%;

  .fieldgroup-field {
    width: 100%;
    margin-bottom: 0;
  }

  input[type=checkbox] {
    position: absolute;
    visibility: hidden;
  }

  label {
    cursor: pointer;
    display: flex;
    align-items: center;
  }

  input[type=checkbox]+label:before {
    content: ' ';
    display: block;
    background-image: url('../images/icons/checkbox-initial.svg');
    width: rem(30);
    height: rem(30);
    margin-right: rem(20);
  }

  input[type=checkbox]:checked+label:before {
    content: '';
    background-image: url('../images/icons/checkbox-checked.svg');
  }
}
