.modal {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  z-index: 10;
  opacity: 0;
  visibility: hidden;
  transition: all .55s cubic-bezier(0.25, 0.8, 0.25, 1);
  background-color: rgba($clr-black, .5);

  @include karla(400, rem(16));
  line-height: rem(24);

  a {
    color:$clr-flamingo;
  }
  &.is-active {
    opacity: 1;
    display:flex;
    justify-content: center;
    pointer-events: all;
    align-items: center;
    visibility: visible;
  }

  &.not-active {
    opacity: 0;
    pointer-events: none;
    visibility: hidden;
    display: none;
  }
}

.modal__content {
  background-color:$clr-white;
  padding:20px;
  width: 80vw;
	margin-top: 100px;
  margin-bottom:30px;
  overflow-y: auto;
  border-radius:10px;
  max-height: 80vh;
  max-width: fit-content;
  @media screen and (min-width:1024px) {
    padding:40px 60px;
  }
  // @include mixin.breakpoint('min', 'desktop'){
  //   padding:6rem;
  // }
}

span.modal__close {
  color:$clr-flamingo;
  margin-top:40px;
  display: block;
  @include karla(600, rem(18));
  cursor: pointer;
}
