/* Grid container 
------------------------------------------*/
.l-grid {
  display: flex;
  justify-content: space-between;
  align-items: stretch;
  flex-wrap: wrap;

  img {
    width: 100%;
    height: auto;
  }
}

.l-grid--nospace {
  display: flex;
}

/* Grid columns
------------------------------------------*/
.l-grid__col {
  display: block;
}

.l-grid__col--1-3 {
  width: 37%;
}

.l-grid__col--2-3 {
  width: 60%;
}

.l-grid__col--3-3 {
  width: 100%;
}

.l-grid__col--side {
  width: 33%
}

.l-grid__col--middle {
  width: 34%;
}

.l-grid__col--wide-ratio {
  padding-top: 20%;
}

/* Grid spacing
------------------------------------------*/
.l-grid--gap-top {
  margin-top: em(30);
}
