.IE-banner {
  position: fixed;
  display: none;
  background: $clr-stratos;
  color: $clr-white;
  padding: 40px;
  width: 100%;
  z-index: 9999;

  >div {
    margin-bottom: 30px;
  }
}

.IE-banner__title {
  @include rubik(700, rem(32));
}
