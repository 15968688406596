/*
* Cart in menu
*/

#cart__btn {
  cursor: pointer;
  display: flex;
  align-items: center;
  background: $clr-white;
  @include rubik(500, rem(16));
  height: rem(90);
  padding: 0 rem(50);
  border: none;
  @include transition(300ms);

  img,
  svg {
    width: rem(30);
    height: auto;
    margin-right: rem(10);

    path {
      @include transition(300ms);
    }
  }
}

.cart-summary {
  position: relative;

  #cart-overview {
    position: absolute;
    display: block;
    background: $clr-alabaster;
    width: 100%;
    height: calc(100vh - 90px);
    top: rem(90);
    right: 0;
    visibility: hidden;
    clip-path: inset(0% 0% 95% 0% round 0 0 0 15%);
    @include transitionCubic(800ms);
  }

  .cart-overview__inner {
    display: block;
    position: relative;
    padding: rem(40) rem(40) rem(80);
    width: 90%;
    max-width: rem(380);
    height: calc(100vh - 90px);
    overflow-y: scroll;
    margin: 0 auto;
  }
}

.cart-overview__products {
  @include karla(400, rem(16));
  color: $clr-stratos;
}

.cart-overview__product {
  position: relative;
  width: 100%;

  .product__close {
    position: absolute;
    background: transparent;
    top: rem(10);
    right: rem(10);
    padding: 0;
    border: none;
  }

  .product__thumb {
    width: 100%;
    padding: rem(20) 0;

    img {
      height: rem(70);
      width: auto;
      margin: 0 auto;
    }
  }

  .product__title {
    @include rubik(500, rem(16));
    margin-bottom: rem(10);
  }

  .product__measurement {
    @include karla(400, rem(14));
  }

  .product__amount {
    display: flex;
    @include rubik(500, rem(16));
    color: $clr-stratos;

    .amount__number {
      display: inline-block;
      background: $clr-white;
      text-align: center;
      padding: rem(10) rem(15);
      margin: 0 rem(10) 0 0;
      border: none;
      width: rem(50);
      @include appearance(none);
      -moz-appearance: textfield;
      @include borderRadius(3px);
      @include shadow(0, 0, 5px, 0.1);

      &::-webkit-outer-spin-button,
      &::-webkit-inner-spin-button {
        @include appearance(none);
        margin: 0;
      }
    }

    .amount__qty {
      cursor: pointer;
      background: $clr-stratos;
      color: $clr-white;
      padding: rem(5) rem(15);
      margin: 0 rem(15);
      @include shadow(0, 0, 10px, 0.1);
      @include transition(300ms);
      margin: 0 rem(10) 0 0;
      border: none;

      &:hover {
        background: $clr-flamingo;
      }
    }
  }

  .product__footer {
    display: flex;
    line-height: rem(30);
  }

  .product__footer {
    margin: rem(20) 0;
  }

  .product__price {
    @include rubik(500, rem(16));
    margin-right: rem(20);
  }

  .product__tax {
    @include karla(400, rem(14));
  }
}

.cart-overview__total {
  display: flex;
  flex-direction: column;
  margin: rem(60) 0 rem(40);

  .total__message {
    @include karla(400, rem(14));
    color: $clr-flamingo;

    strong {
      display: inline-block;
    }
  }
}

.cart-overview__total-price {
  @include rubik(700, rem(16));
  text-transform: uppercase;
  color: $clr-stratos;
}

.cart-overview__disclaimer {
  @include karla(400, rem(14));
  margin-top: rem(5);
}

.cart-overview__navigation {
  display: flex;
  flex-direction: column;
  text-align: center;
  padding-bottom: rem(90);

  .btn-underline {
    text-align: left;
  }

  a {
    margin: rem(10) 0;
  }
}

.cart-overview__close {
  cursor: pointer;
  background: $clr-alabaster;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: rem(20) 0;
  border: none;
  position: absolute;
  @include shadow(0, 0, 20px, 0.1);

  img {
    width: rem(25);
    height: auto;
    margin: 0 auto;
  }
}

.cart-page {
  .form {
    input[type=radio] {
      @include appearance(radio);
    }

    label {
      display: inline-block;
      @include rubik(400, rem(16));
      color: $clr-stratos;
    }

    .js-calculating-message {
      display: block;
      font-size: rem(16);
      margin-top: rem(10);
    }
  }

  #Form_Cart {
    input[type=submit] {
      margin: rem(20) 0;
    }
  }

  input.you-shall-not-pass {
    cursor: default;
  }
}

.cart-page__heading {
  padding: rem(20) 0;
  border-bottom: 1px solid #c3c3c3;
}

.cart-page__heading-title {
  @include rubik(700, rem(16));
  color: $clr-stratos;
}

.cart {
  @include rubik(400, rem(16));
  color: $clr-stratos;
  width: 100%;
  border-collapse: collapse;

  th,
  td {
    padding: rem(30) rem(20);
    text-align: left;
  }
}

.cart-page__total {
  width: 100%;
  max-width: rem(350);
  margin: rem(40) 0;

  .total__row {
    @include rubik(400, rem(16));
    color: $clr-stratos;
    margin: rem(20) 0;
  }

  .total__price {
    font-weight: 500;
  }
}

.cart__table {
  .productAmountInput {
    border: 1px solid #c3c3c3;
    padding: rem(10);
    width: rem(50);
    @include appearance(none);
    -moz-appearance: textfield;
    @include borderRadius(3px);
    text-align: center;

    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      @include appearance(none);
      margin: 0;
    }
  }
}

.cart__head {
  border-collapse: collapse;
  font-weight: 700;
  border-bottom: 1px solid #c3c3c3;
}

.cart__body {
  border-collapse: collapse;

  tr {
    border-bottom: 1px solid #c3c3c3;
  }
}

.cart__mobile__fix{
  min-width: 180px;
}

.cart__product {
  display: flex;
  align-items: center;

  img {
    max-height: rem(90);
    margin-right: rem(40);
  }
}

.cart__product-name {
  @include rubik(700, rem(18));
}

.cart__qty {
  background: $clr-white;
  padding: rem(5) rem(10);
  margin: 0 rem(15);
  @include shadow(0, 0, 10px, 0.1);
  @include transition(300ms);

  &:hover {
    background: $clr-stratos;
    color: $clr-white;
  }
}

.cart__title {
  @include rubik(400, rem(22));
  color: $clr-stratos;
  margin-top: rem(10);
}

.cart__delivery-form {
  @include karla(400, rem(16));
  color: $clr-stratos;
  padding-left: rem(40);

  ul {
    list-style: none;
    padding: 0;
  }
}

.cart__mobile {
  position: relative;
  color: $clr-stratos;
  max-width: rem(300);

  .mobile__remove {
    position: absolute;
    top: rem(10);
    right: rem(10);
    @include karla(400, rem(38));
  }

  .mobile__product {
    width: calc(100% - 50px);

    img {
      display: block;
      width: 90%;
      max-width: rem(350);
      margin: rem(40) 0;
    }
  }

  .mobile__product-title {
    @include rubik(700, rem(22));
    line-height: rem(36);
    text-transform: uppercase;
  }

  .mobile__price {
    display: flex;
    justify-content: space-between;
    width: 100%;
    @include rubik(700, rem(18));
    margin: rem(40) 0;
  }

  .productAmountInput {
    border: 1px solid #c3c3c3;
    padding: rem(10);
    width: rem(50);
    @include appearance(none);
    -moz-appearance: textfield;
    @include borderRadius(3px);
    text-align: center;

    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      @include appearance(none);
      margin: 0;
    }
  }

  .mobile__amount {
    @include rubik(700, rem(18));

    h4 {
      font-weight: 400;
      margin-bottom: rem(40);
    }

    a,
    span {
      @include rubik(400, rem(20));
    }

    .mobile__cty-first {
      margin-left: 0;
    }
  }
}
