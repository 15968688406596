.banner {
  background: $clr-alabaster;
}

.banner__divider {
  width: 50%;
}

.banner__text {
  display: flex;
  justify-content: flex-end;
}

.banner__content {
  @include karla(400, rem(18));
  line-height: rem(32);
  color: $clr-stratos;
  max-width: rem(700);
  width: 90%;
  padding: rem(50);
}

.banner__title {
  @include rubik(700, rem(34));
  color: $clr-stratos;
  text-transform: uppercase;
}

.banner__links {
  display: block;
  margin: rem(40) 0 rem(80);

  a {
    margin-right: rem(20);
    margin-bottom: rem(20);
  }
}

.banner__usp {
  padding: 0;
  list-style: none;
  font-size: rem(16);

  li {
    display: flex;
    align-items: center;
    margin: rem(20) 0;
    text-transform: uppercase;

    img {
      width: rem(30);
      margin-right: rem(15);
    }
  }
}

.banner__image {
  @include borderRadius(50px 0 0 0);
  padding-top: 35%;
}

.banner__bus {
  padding: rem(40) 0;
  width: 50%;
  transform: translateY(rem(80));

  img {
    width: 80%;
    max-width: rem(700);
    height: auto;
    transform: scale(1.4);
  }
}
