.dashboard {
  .form {
    label {
      display: inline-block;
      @include rubik(500, rem(16));
    }
  }

  form {
    .description {
      display: none;
    }
  }

  .dashboard__orders {
    overflow-x: scroll;
    width: 100%;
  }
}

.dashboard__orders {
  .cart__product {
    img {
      width: rem(60);
    }
  }
}

.dashboard__header {
  display: block;
  border-bottom: 1px solid #c3c3c3;
  height: rem(60);
}

.dashboard__title {
  margin: rem(10) 0;
  font-size: rem(30);
}

.dashboard__name {
  color: $clr-flamingo;
}

.dashboard__menu {
  padding: 0;
  list-style: none;

  li {
    margin: rem(10) 0;
  }

  .menu__item {
    @include rubik(500, rem(16));
    color: $clr-stratos;
    text-transform: uppercase;
  }
}
