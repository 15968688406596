/*	1	Base
-----------------------------------------*/
/*-- Regular Mixins --*/ /*
* Color naming by: http://chir.ag/projects/name-that-color/
*/
/* Standard */
/* Brand */
/* Alpha */
/* rubik-regular - latin */
@font-face {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 400;
  src: url("../fonts/rubik/rubik-v9-latin-regular.eot");
  /* IE9 Compat Modes */
  src: local("Rubik"), local("Rubik-Regular"), url("../fonts/rubik/rubik-v9-latin-regular.eot?#iefix") format("embedded-opentype"), url("../fonts/rubik/rubik-v9-latin-regular.woff2") format("woff2"), url("../fonts/rubik/rubik-v9-latin-regular.woff") format("woff"), url("../fonts/rubik/rubik-v9-latin-regular.ttf") format("truetype"), url("../fonts/rubik/rubik-v9-latin-regular.svg#Rubik") format("svg");
  /* Legacy iOS */
  font-display: swap;
}
/* rubik-500 - latin */
@font-face {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 500;
  src: url("../fonts/rubik-v9-latin-500.eot");
  /* IE9 Compat Modes */
  src: local("Rubik Medium"), local("Rubik-Medium"), url("../fonts/rubik/rubik-v9-latin-500.eot?#iefix") format("embedded-opentype"), url("../fonts/rubik/rubik-v9-latin-500.woff2") format("woff2"), url("../fonts/rubik/rubik-v9-latin-500.woff") format("woff"), url("../fonts/rubik/rubik-v9-latin-500.ttf") format("truetype"), url("../fonts/rubik/rubik-v9-latin-500.svg#Rubik") format("svg");
  /* Legacy iOS */
  font-display: swap;
}
/* rubik-700 - latin */
@font-face {
  font-family: "Rubik";
  font-style: normal;
  font-weight: 700;
  src: url("../fonts/rubik-v9-latin-700.eot");
  /* IE9 Compat Modes */
  src: local("Rubik Bold"), local("Rubik-Bold"), url("../fonts/rubik/rubik-v9-latin-700.eot?#iefix") format("embedded-opentype"), url("../fonts/rubik/rubik-v9-latin-700.woff2") format("woff2"), url("../fonts/rubik/rubik-v9-latin-700.woff") format("woff"), url("../fonts/rubik/rubik-v9-latin-700.ttf") format("truetype"), url("../fonts/rubik/rubik-v9-latin-700.svg#Rubik") format("svg");
  /* Legacy iOS */
  font-display: swap;
}
/* karla-regular - latin */
@font-face {
  font-family: "Karla";
  font-style: normal;
  font-weight: 400;
  src: url("../fonts/karla/karla-v13-latin-regular.eot");
  /* IE9 Compat Modes */
  src: local("Karla"), local("Karla-Regular"), url("../fonts/karla/karla-v13-latin-regular.eot?#iefix") format("embedded-opentype"), url("../fonts/karla/karla-v13-latin-regular.woff2") format("woff2"), url("../fonts/karla/karla-v13-latin-regular.woff") format("woff"), url("../fonts/karla/karla-v13-latin-regular.ttf") format("truetype"), url("../fonts/karla/karla-v13-latin-regular.svg#Karla") format("svg");
  /* Legacy iOS */
  font-display: swap;
}
/* karla-700 - latin */
@font-face {
  font-family: "Karla";
  font-style: normal;
  font-weight: 700;
  src: url("../fonts/karla-v13-latin-700.eot");
  /* IE9 Compat Modes */
  src: local("Karla Bold"), local("Karla-Bold"), url("../fonts/karla/karla-v13-latin-700.eot?#iefix") format("embedded-opentype"), url("../fonts/karla/karla-v13-latin-700.woff2") format("woff2"), url("../fonts/karla/karla-v13-latin-700.woff") format("woff"), url("../fonts/karla/karla-v13-latin-700.ttf") format("truetype"), url("../fonts/karla/karla-v13-latin-700.svg#Karla") format("svg");
  /* Legacy iOS */
  font-display: swap;
}
/* Fonts styles */
/*-- Basic styles --*/
html,
body {
  -ms-overflow-style: -ms-autohiding-scrollbar;
  position: relative;
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
}

* {
  box-sizing: border-box;
}

a {
  text-decoration: none;
  color: inherit;
  -webkit-transition: all 300ms ease-in-out;
  -moz-transition: all 300ms ease-in-out;
  -o-transition: all 300ms ease-in-out;
  transition: all 300ms ease-in-out;
}

figure {
  margin: 0;
}

img {
  vertical-align: bottom;
}

textarea:focus,
input:focus,
button:focus {
  outline: none;
}

main {
  z-index: 3;
  position: relative;
}

input,
textarea {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  border-radius: 0;
}

input[type=checkbox] {
  -webkit-appearance: checkbox;
  -moz-appearance: checkbox;
  appearance: checkbox;
}

#mc_embed_signup form {
  padding: 0 !important;
}

/*	2	Layout
-----------------------------------------*/
.l-wrapper {
  width: 90%;
  max-width: 72.5em;
  margin: 0 auto;
}

.l-wrapper--small {
  width: 90%;
  max-width: 34.375em;
  margin: 0 auto;
}

.l-wrapper--medium {
  width: 90%;
  max-width: 60em;
  margin: 0 auto;
}

.l-wrapper--large {
  width: 90%;
  max-width: 90em;
  margin: 0 auto;
}

.l-wrapper--full-width {
  width: 100%;
}

.l-wrapper--contact {
  width: 90%;
  max-width: 51.25rem;
  margin: 0 auto;
}

.l-top-space {
  margin-top: 4.375em;
}

.l-top-space--small {
  margin-top: 2.5em;
}

.l-top-space--large {
  margin-top: 8.75em;
}

.l-top-space--banner {
  margin-top: 11.25em;
}

.l-top-space--negative {
  margin-top: -15%;
}

.l-bottom-space {
  margin-bottom: 5em;
}

.l-bottom-space--small {
  margin-bottom: 2.5em;
}

/* Grid container 
------------------------------------------*/
.l-grid {
  display: flex;
  justify-content: space-between;
  align-items: stretch;
  flex-wrap: wrap;
}
.l-grid img {
  width: 100%;
  height: auto;
}

.l-grid--nospace {
  display: flex;
}

/* Grid columns
------------------------------------------*/
.l-grid__col {
  display: block;
}

.l-grid__col--1-3 {
  width: 37%;
}

.l-grid__col--2-3 {
  width: 60%;
}

.l-grid__col--3-3 {
  width: 100%;
}

.l-grid__col--side {
  width: 33%;
}

.l-grid__col--middle {
  width: 34%;
}

.l-grid__col--wide-ratio {
  padding-top: 20%;
}

/* Grid spacing
------------------------------------------*/
.l-grid--gap-top {
  margin-top: 1.875em;
}

.l-columns {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.l-columns--baseline {
  align-items: baseline;
}

.l-column__gutter-bottom {
  margin-bottom: 4%;
}

.l-column__col2 {
  width: 48%;
}

.l-column__col3 {
  width: 31%;
}

.l-column__col4 {
  width: 24%;
}

.l-column__col3--large {
  width: 32%;
}

.l-flex {
  display: flex;
  flex-wrap: wrap;
}

.l-flex--container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 100vh;
}

.l-flex--between {
  justify-content: space-between;
}

.l-flex--center {
  justify-content: center;
}

.l-flex--align-center {
  align-items: center;
}

.l-flex__c30 {
  width: 30%;
}

.l-flex__c40 {
  width: 40%;
}

.l-flex__c50 {
  width: 50%;
}

.l-flex__c60 {
  width: 60%;
}

/*	3	Plugins
-----------------------------------------*/
/*
*   from: https://www.npmjs.com/package/slick-carousel
*/
/* Slider */
.slick-slider {
  position: relative;
  display: block;
  box-sizing: border-box;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-touch-callout: none;
  -khtml-user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent;
}

.slick-list {
  position: relative;
  display: block;
  overflow: hidden;
  margin: 0;
  padding: 0;
}

.slick-list:focus {
  outline: none;
}

.slick-list.dragging {
  cursor: pointer;
  cursor: hand;
}

.slick-slider .slick-track,
.slick-slider .slick-list {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

.slick-track {
  position: relative;
  top: 0;
  left: 0;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.slick-track:before,
.slick-track:after {
  display: table;
  content: "";
}

.slick-track:after {
  clear: both;
}

.slick-loading .slick-track {
  visibility: hidden;
}

.slick-slide {
  display: none;
  float: left;
  height: 100%;
  min-height: 1px;
}

[dir=rtl] .slick-slide {
  float: right;
}

.slick-slide img {
  display: block;
}

.slick-slide.slick-loading img {
  display: none;
}

.slick-slide.dragging img {
  pointer-events: none;
}

.slick-initialized .slick-slide {
  display: block;
}

.slick-loading .slick-slide {
  visibility: hidden;
}

.slick-vertical .slick-slide {
  display: block;
  height: auto;
  border: 1px solid transparent;
}

.slick-arrow.slick-hidden {
  display: none;
}

.flatpickr-calendar {
  background: transparent;
  opacity: 0;
  display: none;
  text-align: center;
  visibility: hidden;
  padding: 0;
  -webkit-animation: none;
  animation: none;
  direction: ltr;
  border: 0;
  font-size: 14px;
  line-height: 24px;
  border-radius: 5px;
  position: absolute;
  width: 307.875px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  -ms-touch-action: manipulation;
  touch-action: manipulation;
  background: #fff;
  -webkit-box-shadow: 1px 0 0 #e6e6e6, -1px 0 0 #e6e6e6, 0 1px 0 #e6e6e6, 0 -1px 0 #e6e6e6, 0 3px 13px rgba(0, 0, 0, 0.08);
  box-shadow: 1px 0 0 #e6e6e6, -1px 0 0 #e6e6e6, 0 1px 0 #e6e6e6, 0 -1px 0 #e6e6e6, 0 3px 13px rgba(0, 0, 0, 0.08);
}

.flatpickr-calendar.open,
.flatpickr-calendar.inline {
  opacity: 1;
  max-height: 640px;
  visibility: visible;
}

.flatpickr-calendar.open {
  display: inline-block;
  z-index: 99999;
}

.flatpickr-calendar.animate.open {
  -webkit-animation: fpFadeInDown 300ms cubic-bezier(0.23, 1, 0.32, 1);
  animation: fpFadeInDown 300ms cubic-bezier(0.23, 1, 0.32, 1);
}

.flatpickr-calendar.inline {
  display: block;
  position: relative;
  top: 2px;
}

.flatpickr-calendar.static {
  position: absolute;
  top: calc(100% + 2px);
}

.flatpickr-calendar.static.open {
  z-index: 999;
  display: block;
}

.flatpickr-calendar.multiMonth .flatpickr-days .dayContainer:nth-child(n+1) .flatpickr-day.inRange:nth-child(7n+7) {
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
}

.flatpickr-calendar.multiMonth .flatpickr-days .dayContainer:nth-child(n+2) .flatpickr-day.inRange:nth-child(7n+1) {
  -webkit-box-shadow: -2px 0 0 #e6e6e6, 5px 0 0 #e6e6e6;
  box-shadow: -2px 0 0 #e6e6e6, 5px 0 0 #e6e6e6;
}

.flatpickr-calendar .hasWeeks .dayContainer,
.flatpickr-calendar .hasTime .dayContainer {
  border-bottom: 0;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.flatpickr-calendar .hasWeeks .dayContainer {
  border-left: 0;
}

.flatpickr-calendar.showTimeInput.hasTime .flatpickr-time {
  height: 40px;
  border-top: 1px solid #e6e6e6;
}

.flatpickr-calendar.noCalendar.hasTime .flatpickr-time {
  height: auto;
}

.flatpickr-calendar:before,
.flatpickr-calendar:after {
  position: absolute;
  display: block;
  pointer-events: none;
  border: solid transparent;
  content: "";
  height: 0;
  width: 0;
  left: 22px;
}

.flatpickr-calendar.rightMost:before,
.flatpickr-calendar.rightMost:after {
  left: auto;
  right: 22px;
}

.flatpickr-calendar:before {
  border-width: 5px;
  margin: 0 -5px;
}

.flatpickr-calendar:after {
  border-width: 4px;
  margin: 0 -4px;
}

.flatpickr-calendar.arrowTop:before,
.flatpickr-calendar.arrowTop:after {
  bottom: 100%;
}

.flatpickr-calendar.arrowTop:before {
  border-bottom-color: #e6e6e6;
}

.flatpickr-calendar.arrowTop:after {
  border-bottom-color: #fff;
}

.flatpickr-calendar.arrowBottom:before,
.flatpickr-calendar.arrowBottom:after {
  top: 100%;
}

.flatpickr-calendar.arrowBottom:before {
  border-top-color: #e6e6e6;
}

.flatpickr-calendar.arrowBottom:after {
  border-top-color: #fff;
}

.flatpickr-calendar:focus {
  outline: 0;
}

.flatpickr-wrapper {
  position: relative;
  display: inline-block;
}

.flatpickr-months {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
}

.flatpickr-months .flatpickr-month {
  background: transparent;
  color: rgba(0, 0, 0, 0.9);
  fill: rgba(0, 0, 0, 0.9);
  height: 34px;
  line-height: 1;
  text-align: center;
  position: relative;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  overflow: hidden;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
}

.flatpickr-months .flatpickr-prev-month,
.flatpickr-months .flatpickr-next-month {
  text-decoration: none;
  cursor: pointer;
  position: absolute;
  top: 0;
  height: 34px;
  padding: 10px;
  z-index: 3;
  color: rgba(0, 0, 0, 0.9);
  fill: rgba(0, 0, 0, 0.9);
}

.flatpickr-months .flatpickr-prev-month.flatpickr-disabled,
.flatpickr-months .flatpickr-next-month.flatpickr-disabled {
  display: none;
}

.flatpickr-months .flatpickr-prev-month i,
.flatpickr-months .flatpickr-next-month i {
  position: relative;
}

.flatpickr-months .flatpickr-prev-month.flatpickr-prev-month,
.flatpickr-months .flatpickr-next-month.flatpickr-prev-month {
  /*
      /*rtl:begin:ignore*/
  left: 0;
  /*
      /*rtl:end:ignore*/
}

/*
      /*rtl:begin:ignore*/
/*
      /*rtl:end:ignore*/
.flatpickr-months .flatpickr-prev-month.flatpickr-next-month,
.flatpickr-months .flatpickr-next-month.flatpickr-next-month {
  /*
      /*rtl:begin:ignore*/
  right: 0;
  /*
      /*rtl:end:ignore*/
}

/*
      /*rtl:begin:ignore*/
/*
      /*rtl:end:ignore*/
.flatpickr-months .flatpickr-prev-month:hover,
.flatpickr-months .flatpickr-next-month:hover {
  color: #959ea9;
}

.flatpickr-months .flatpickr-prev-month:hover svg,
.flatpickr-months .flatpickr-next-month:hover svg {
  fill: #f64747;
}

.flatpickr-months .flatpickr-prev-month svg,
.flatpickr-months .flatpickr-next-month svg {
  width: 14px;
  height: 14px;
}

.flatpickr-months .flatpickr-prev-month svg path,
.flatpickr-months .flatpickr-next-month svg path {
  -webkit-transition: fill 0.1s;
  transition: fill 0.1s;
  fill: inherit;
}

.numInputWrapper {
  position: relative;
  height: auto;
}

.numInputWrapper input,
.numInputWrapper span {
  display: inline-block;
}

.numInputWrapper input {
  width: 100%;
}

.numInputWrapper input::-ms-clear {
  display: none;
}

.numInputWrapper input::-webkit-outer-spin-button,
.numInputWrapper input::-webkit-inner-spin-button {
  margin: 0;
  -webkit-appearance: none;
}

.numInputWrapper span {
  position: absolute;
  right: 0;
  width: 14px;
  padding: 0 4px 0 2px;
  height: 50%;
  line-height: 50%;
  opacity: 0;
  cursor: pointer;
  border: 1px solid rgba(57, 57, 57, 0.15);
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

.numInputWrapper span:hover {
  background: rgba(0, 0, 0, 0.1);
}

.numInputWrapper span:active {
  background: rgba(0, 0, 0, 0.2);
}

.numInputWrapper span:after {
  display: block;
  content: "";
  position: absolute;
}

.numInputWrapper span.arrowUp {
  top: 0;
  border-bottom: 0;
}

.numInputWrapper span.arrowUp:after {
  border-left: 4px solid transparent;
  border-right: 4px solid transparent;
  border-bottom: 4px solid rgba(57, 57, 57, 0.6);
  top: 26%;
}

.numInputWrapper span.arrowDown {
  top: 50%;
}

.numInputWrapper span.arrowDown:after {
  border-left: 4px solid transparent;
  border-right: 4px solid transparent;
  border-top: 4px solid rgba(57, 57, 57, 0.6);
  top: 40%;
}

.numInputWrapper span svg {
  width: inherit;
  height: auto;
}

.numInputWrapper span svg path {
  fill: rgba(0, 0, 0, 0.5);
}

.numInputWrapper:hover {
  background: rgba(0, 0, 0, 0.05);
}

.numInputWrapper:hover span {
  opacity: 1;
}

.flatpickr-current-month {
  font-size: 135%;
  line-height: inherit;
  font-weight: 300;
  color: inherit;
  position: absolute;
  width: 75%;
  left: 12.5%;
  padding: 7.48px 0 0 0;
  line-height: 1;
  height: 34px;
  display: inline-block;
  text-align: center;
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

.flatpickr-current-month span.cur-month {
  font-family: inherit;
  font-weight: 700;
  color: inherit;
  display: inline-block;
  margin-left: 0.5ch;
  padding: 0;
}

.flatpickr-current-month span.cur-month:hover {
  background: rgba(0, 0, 0, 0.05);
}

.flatpickr-current-month .numInputWrapper {
  width: 6ch;
  width: 7ch\0 ;
  display: inline-block;
}

.flatpickr-current-month .numInputWrapper span.arrowUp:after {
  border-bottom-color: rgba(0, 0, 0, 0.9);
}

.flatpickr-current-month .numInputWrapper span.arrowDown:after {
  border-top-color: rgba(0, 0, 0, 0.9);
}

.flatpickr-current-month input.cur-year {
  background: transparent;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  color: inherit;
  cursor: text;
  padding: 0 0 0 0.5ch;
  margin: 0;
  display: inline-block;
  font-size: inherit;
  font-family: inherit;
  font-weight: 300;
  line-height: inherit;
  height: auto;
  border: 0;
  border-radius: 0;
  vertical-align: initial;
  -webkit-appearance: textfield;
  -moz-appearance: textfield;
  appearance: textfield;
}

.flatpickr-current-month input.cur-year:focus {
  outline: 0;
}

.flatpickr-current-month input.cur-year[disabled],
.flatpickr-current-month input.cur-year[disabled]:hover {
  font-size: 100%;
  color: rgba(0, 0, 0, 0.5);
  background: transparent;
  pointer-events: none;
}

.flatpickr-current-month .flatpickr-monthDropdown-months {
  appearance: menulist;
  background: transparent;
  border: none;
  border-radius: 0;
  box-sizing: border-box;
  color: inherit;
  cursor: pointer;
  font-size: inherit;
  font-family: inherit;
  font-weight: 300;
  height: auto;
  line-height: inherit;
  margin: -1px 0 0 0;
  outline: none;
  padding: 0 0 0 0.5ch;
  position: relative;
  vertical-align: initial;
  -webkit-box-sizing: border-box;
  -webkit-appearance: menulist;
  -moz-appearance: menulist;
  width: auto;
}

.flatpickr-current-month .flatpickr-monthDropdown-months:focus,
.flatpickr-current-month .flatpickr-monthDropdown-months:active {
  outline: none;
}

.flatpickr-current-month .flatpickr-monthDropdown-months:hover {
  background: rgba(0, 0, 0, 0.05);
}

.flatpickr-current-month .flatpickr-monthDropdown-months .flatpickr-monthDropdown-month {
  background-color: transparent;
  outline: none;
  padding: 0;
}

.flatpickr-weekdays {
  background: transparent;
  text-align: center;
  overflow: hidden;
  width: 100%;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  height: 28px;
}

.flatpickr-weekdays .flatpickr-weekdaycontainer {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
}

span.flatpickr-weekday {
  cursor: default;
  font-size: 90%;
  background: transparent;
  color: rgba(0, 0, 0, 0.54);
  line-height: 1;
  margin: 0;
  text-align: center;
  display: block;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
  font-weight: bolder;
}

.dayContainer,
.flatpickr-weeks {
  padding: 1px 0 0 0;
}

.flatpickr-days {
  position: relative;
  overflow: hidden;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
  width: 307.875px;
}

.flatpickr-days:focus {
  outline: 0;
}

.dayContainer {
  padding: 0;
  outline: 0;
  text-align: left;
  width: 307.875px;
  min-width: 307.875px;
  max-width: 307.875px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  display: inline-block;
  display: -ms-flexbox;
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-wrap: wrap;
  flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  -ms-flex-pack: justify;
  -webkit-justify-content: space-around;
  justify-content: space-around;
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
  opacity: 1;
}

.dayContainer + .dayContainer {
  -webkit-box-shadow: -1px 0 0 #e6e6e6;
  box-shadow: -1px 0 0 #e6e6e6;
}

.flatpickr-day {
  background: none;
  border: 1px solid transparent;
  border-radius: 150px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  color: #393939;
  cursor: pointer;
  font-weight: 400;
  width: 14.2857143%;
  -webkit-flex-basis: 14.2857143%;
  -ms-flex-preferred-size: 14.2857143%;
  flex-basis: 14.2857143%;
  max-width: 39px;
  height: 39px;
  line-height: 39px;
  margin: 0;
  display: inline-block;
  position: relative;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  text-align: center;
}

.flatpickr-day.inRange,
.flatpickr-day.prevMonthDay.inRange,
.flatpickr-day.nextMonthDay.inRange,
.flatpickr-day.today.inRange,
.flatpickr-day.prevMonthDay.today.inRange,
.flatpickr-day.nextMonthDay.today.inRange,
.flatpickr-day:hover,
.flatpickr-day.prevMonthDay:hover,
.flatpickr-day.nextMonthDay:hover,
.flatpickr-day:focus,
.flatpickr-day.prevMonthDay:focus,
.flatpickr-day.nextMonthDay:focus {
  cursor: pointer;
  outline: 0;
  background: #e6e6e6;
  border-color: #e6e6e6;
}

.flatpickr-day.today {
  border-color: #959ea9;
}

.flatpickr-day.today:hover,
.flatpickr-day.today:focus {
  border-color: #959ea9;
  background: #959ea9;
  color: #fff;
}

.flatpickr-day.selected,
.flatpickr-day.startRange,
.flatpickr-day.endRange,
.flatpickr-day.selected.inRange,
.flatpickr-day.startRange.inRange,
.flatpickr-day.endRange.inRange,
.flatpickr-day.selected:focus,
.flatpickr-day.startRange:focus,
.flatpickr-day.endRange:focus,
.flatpickr-day.selected:hover,
.flatpickr-day.startRange:hover,
.flatpickr-day.endRange:hover,
.flatpickr-day.selected.prevMonthDay,
.flatpickr-day.startRange.prevMonthDay,
.flatpickr-day.endRange.prevMonthDay,
.flatpickr-day.selected.nextMonthDay,
.flatpickr-day.startRange.nextMonthDay,
.flatpickr-day.endRange.nextMonthDay {
  background: #569ff7;
  -webkit-box-shadow: none;
  box-shadow: none;
  color: #fff;
  border-color: #569ff7;
}

.flatpickr-day.selected.startRange,
.flatpickr-day.startRange.startRange,
.flatpickr-day.endRange.startRange {
  border-radius: 50px 0 0 50px;
}

.flatpickr-day.selected.endRange,
.flatpickr-day.startRange.endRange,
.flatpickr-day.endRange.endRange {
  border-radius: 0 50px 50px 0;
}

.flatpickr-day.selected.startRange + .endRange:not(:nth-child(7n+1)),
.flatpickr-day.startRange.startRange + .endRange:not(:nth-child(7n+1)),
.flatpickr-day.endRange.startRange + .endRange:not(:nth-child(7n+1)) {
  -webkit-box-shadow: -10px 0 0 #569ff7;
  box-shadow: -10px 0 0 #569ff7;
}

.flatpickr-day.selected.startRange.endRange,
.flatpickr-day.startRange.startRange.endRange,
.flatpickr-day.endRange.startRange.endRange {
  border-radius: 50px;
}

.flatpickr-day.inRange {
  border-radius: 0;
  -webkit-box-shadow: -5px 0 0 #e6e6e6, 5px 0 0 #e6e6e6;
  box-shadow: -5px 0 0 #e6e6e6, 5px 0 0 #e6e6e6;
}

.flatpickr-day.flatpickr-disabled,
.flatpickr-day.flatpickr-disabled:hover,
.flatpickr-day.prevMonthDay,
.flatpickr-day.nextMonthDay,
.flatpickr-day.notAllowed,
.flatpickr-day.notAllowed.prevMonthDay,
.flatpickr-day.notAllowed.nextMonthDay {
  color: rgba(57, 57, 57, 0.3);
  background: transparent;
  border-color: transparent;
  cursor: default;
}

.flatpickr-day.flatpickr-disabled,
.flatpickr-day.flatpickr-disabled:hover {
  cursor: not-allowed;
  color: rgba(57, 57, 57, 0.1);
}

.flatpickr-day.week.selected {
  border-radius: 0;
  -webkit-box-shadow: -5px 0 0 #569ff7, 5px 0 0 #569ff7;
  box-shadow: -5px 0 0 #569ff7, 5px 0 0 #569ff7;
}

.flatpickr-day.hidden {
  visibility: hidden;
}

.rangeMode .flatpickr-day {
  margin-top: 1px;
}

.flatpickr-weekwrapper {
  float: left;
}

.flatpickr-weekwrapper .flatpickr-weeks {
  padding: 0 12px;
  -webkit-box-shadow: 1px 0 0 #e6e6e6;
  box-shadow: 1px 0 0 #e6e6e6;
}

.flatpickr-weekwrapper .flatpickr-weekday {
  float: none;
  width: 100%;
  line-height: 28px;
}

.flatpickr-weekwrapper span.flatpickr-day,
.flatpickr-weekwrapper span.flatpickr-day:hover {
  display: block;
  width: 100%;
  max-width: none;
  color: rgba(57, 57, 57, 0.3);
  background: transparent;
  cursor: default;
  border: none;
}

.flatpickr-innerContainer {
  display: block;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  overflow: hidden;
}

.flatpickr-rContainer {
  display: inline-block;
  padding: 0;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

.flatpickr-time {
  text-align: center;
  outline: 0;
  display: block;
  height: 0;
  line-height: 40px;
  max-height: 40px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  overflow: hidden;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
}

.flatpickr-time:after {
  content: "";
  display: table;
  clear: both;
}

.flatpickr-time .numInputWrapper {
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
  width: 40%;
  height: 40px;
  float: left;
}

.flatpickr-time .numInputWrapper span.arrowUp:after {
  border-bottom-color: #393939;
}

.flatpickr-time .numInputWrapper span.arrowDown:after {
  border-top-color: #393939;
}

.flatpickr-time.hasSeconds .numInputWrapper {
  width: 26%;
}

.flatpickr-time.time24hr .numInputWrapper {
  width: 49%;
}

.flatpickr-time input {
  background: transparent;
  -webkit-box-shadow: none;
  box-shadow: none;
  border: 0;
  border-radius: 0;
  text-align: center;
  margin: 0;
  padding: 0;
  height: inherit;
  line-height: inherit;
  color: #393939;
  font-size: 14px;
  position: relative;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-appearance: textfield;
  -moz-appearance: textfield;
  appearance: textfield;
}

.flatpickr-time input.flatpickr-hour {
  font-weight: bold;
}

.flatpickr-time input.flatpickr-minute,
.flatpickr-time input.flatpickr-second {
  font-weight: 400;
}

.flatpickr-time input:focus {
  outline: 0;
  border: 0;
}

.flatpickr-time .flatpickr-time-separator,
.flatpickr-time .flatpickr-am-pm {
  height: inherit;
  float: left;
  line-height: inherit;
  color: #393939;
  font-weight: bold;
  width: 2%;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-align-self: center;
  -ms-flex-item-align: center;
  align-self: center;
}

.flatpickr-time .flatpickr-am-pm {
  outline: 0;
  width: 18%;
  cursor: pointer;
  text-align: center;
  font-weight: 400;
}

.flatpickr-time input:hover,
.flatpickr-time .flatpickr-am-pm:hover,
.flatpickr-time input:focus,
.flatpickr-time .flatpickr-am-pm:focus {
  background: #eee;
}

.flatpickr-input[readonly] {
  cursor: pointer;
}

@-webkit-keyframes fpFadeInDown {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, -20px, 0);
    transform: translate3d(0, -20px, 0);
  }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}
@keyframes fpFadeInDown {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, -20px, 0);
    transform: translate3d(0, -20px, 0);
  }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}
/*	4	Components
-----------------------------------------*/
.container {
  display: flex;
  flex-direction: column;
  padding-top: 5.625rem;
  flex: 1;
}

.bg-cover {
  -webkit-background-size: cover !important;
  -moz-background-size: cover !important;
  -o-background-size: cover !important;
  background-size: cover !important;
  background-position: center center;
  background-repeat: no-repeat;
}

.title {
  text-align: center;
  margin: 0.9375rem 0;
}

.title--left {
  text-align: left;
}

.title--main {
  font-family: "Karla", sans-serif;
  font-weight: 700;
  font-size: 1.125rem;
  color: #ec6535;
}

.title--sub {
  font-family: "Rubik", sans-serif;
  font-weight: 700;
  font-size: 1.625rem;
  color: #010b40;
  text-transform: uppercase;
  line-height: 2rem;
  margin: 0.625rem 0;
}

.IE-banner {
  position: fixed;
  display: none;
  background: #010b40;
  color: #ffffff;
  padding: 40px;
  width: 100%;
  z-index: 9999;
}
.IE-banner > div {
  margin-bottom: 30px;
}

.IE-banner__title {
  font-family: "Rubik", sans-serif;
  font-weight: 700;
  font-size: 2rem;
}

.btn {
  cursor: pointer;
  display: inline-block;
  font-family: "Rubik", sans-serif;
  font-weight: 500;
  font-size: 0.875rem;
  -webkit-border-radius: 6px;
  -moz-border-radius: 6px;
  border-radius: 6px;
  padding: 0.9375rem 1.25rem;
  border: 3px solid transparent;
  -webkit-transition: all 300ms ease-in-out;
  -moz-transition: all 300ms ease-in-out;
  -o-transition: all 300ms ease-in-out;
  transition: all 300ms ease-in-out;
}

.btn--shadow {
  -webkit-box-shadow: 0 0 15px 0px rgba(0, 0, 0, 0.25);
  -moz-box-shadow: 0 0 15px 0px rgba(0, 0, 0, 0.25);
  box-shadow: 0 0 15px 0px rgba(0, 0, 0, 0.25);
}

.btn--orange-fill {
  background: #ec6535;
  color: #ffffff;
  border-color: #ec6535;
}
.btn--orange-fill:hover {
  background: transparent;
  color: #ec6535;
}

.btn--blue-fill {
  background: #010b40;
  color: #ffffff;
  border-color: #010b40;
}
.btn--blue-fill:hover {
  background: transparent;
  color: #010b40;
}

.btn--orange-stroke {
  background: transparent;
  border-color: #ec6535;
  color: #ec6535;
}
.btn--orange-stroke:hover {
  background: #ec6535;
  color: #ffffff;
}

.btn-underline {
  font-family: "Rubik", sans-serif;
  font-weight: 700;
  font-size: 0.875rem;
  text-decoration: underline;
}

.btn__plus {
  display: inline-block;
  font-weight: 400;
  margin: 0 0 0 0.625rem;
  transform: scale(1.5);
}

.form #Factuurgegevens .left,
.form h3 {
  font-family: "Rubik", sans-serif;
  font-weight: 500;
  font-size: 1.5rem;
  color: #010b40;
}
.form h4 {
  font-family: "Rubik", sans-serif;
  font-weight: 500;
  font-size: 1rem;
  color: #010b40;
  margin-top: 1.25rem;
  margin-bottom: 1.875rem;
}
.form fieldset {
  border: none;
  padding: 0;
}
.form fieldset legend {
  display: none;
}
.form .field {
  display: block;
  margin: 1.25rem 0 2.1875rem;
}
.form .field label {
  margin-bottom: 0.625rem;
}
.form label {
  display: inline-block;
  font-family: "Rubik", sans-serif;
  font-weight: 500;
  font-size: 1rem;
  color: #262628;
  margin-bottom: 1.25rem;
}
.form .input-required-text {
  font-family: "Karla", sans-serif;
  font-weight: 400;
  font-size: 0.875rem;
  margin: 0 0.3125rem;
}
.form .input-required-indicator {
  font-family: "Karla", sans-serif;
  font-weight: 700;
  font-size: 1.125rem;
  color: #ec6535;
}
.form .validation-message {
  display: block;
  font-family: "Karla", sans-serif;
  font-weight: 400;
  font-size: 0.875rem;
  color: #ec6535;
  margin-top: 0.625rem;
}
.form .userformsgroup .middleColumn {
  display: flex;
  justify-content: space-between;
}
.form .userformsgroup .middleColumn .field {
  width: 48%;
  margin: 0;
}
.form input[type=text],
.form input[type=email],
.form input[type=password],
.form textarea,
.form span.readonly {
  background: #ffffff;
  font-family: "Karla", sans-serif;
  font-weight: 400;
  font-size: 0.875rem;
  color: #262628;
  padding: 0.9375rem 1.25rem;
  height: 3.125rem;
  width: 100%;
  border: none;
  -webkit-border-radius: 6px;
  -moz-border-radius: 6px;
  border-radius: 6px;
  box-shadow: 0 0 20px 0 rgba(62, 106, 161, 0.2);
  border: 1px solid #E7E7E7;
}
.form span.readonly {
  display: block;
  width: 100%;
}
.form textarea {
  height: auto;
}
.form input[type=checkbox],
.form input[type=checkbox] + label {
  display: inline;
}
.form input[type=submit] {
  cursor: pointer;
  display: inline-block;
  font-family: "Rubik", sans-serif;
  font-weight: 500;
  font-size: 0.875rem;
  -webkit-border-radius: 6px;
  -moz-border-radius: 6px;
  border-radius: 6px;
  padding: 0.9375rem 1.25rem;
  border: 3px solid transparent;
  background: #010b40;
  color: #ffffff;
  box-shadow: 0 0 20px 0 rgba(62, 106, 161, 0.2);
  -webkit-transition: all 300ms ease-in-out;
  -moz-transition: all 300ms ease-in-out;
  -o-transition: all 300ms ease-in-out;
  transition: all 300ms ease-in-out;
}
.form input[type=submit]:hover {
  background: transparent;
  border-color: #010b40;
  color: #010b40;
}

.createAccount {
  max-width: 25rem;
  margin: 0 auto;
  text-align: left;
  padding-left: 2.5rem;
}
.createAccount .title {
  text-align: left;
}
.createAccount label {
  display: block;
  font-weight: 700;
  margin-bottom: 1.875rem;
}
.createAccount fieldset div.field {
  width: 100% !important;
}

.login {
  background-color: #fafafa;
  padding: 5rem 0;
  font-family: "Karla", sans-serif;
  font-weight: 400;
  font-size: 1rem;
  flex-grow: 1;
}
.login label {
  display: inline-block;
}
.login input {
  width: 100%;
}
.login input[type=submit],
.login input[type=checkbox] {
  width: auto;
}
.login a {
  display: block;
  color: #010b40;
  margin-top: 3.75rem;
}
.login .description {
  display: none;
}

.login__header {
  border-bottom: 1px solid #c3c3c3;
  padding-bottom: 1.875rem;
}

.login__sub-title {
  font-family: "Rubik", sans-serif;
  font-weight: 500;
  font-size: 1.5rem;
  color: #010b40;
}

.login__half {
  width: 45%;
}

.content {
  font-family: "Karla", sans-serif;
  font-weight: 400;
  font-size: 1rem;
  color: #010b40;
  line-height: 1.5rem;
}
.content h2 {
  font-family: "Rubik", sans-serif;
  font-weight: 700;
  font-size: 1.75rem;
  line-height: 2.125rem;
}
.content .cubes-illustration {
  width: 100%;
  max-width: 13.75rem;
  margin: 0 auto 3.75rem;
}
.content .cubes-illustration img {
  width: 100%;
  height: auto;
}
.content .color-orange {
  color: #ec6535;
}
.content .color-white {
  color: #ffffff;
}
.content .text-right {
  text-align: right;
}

.content__intro {
  font-family: "Karla", sans-serif;
  font-weight: 400;
  font-size: 1.625rem;
  line-height: 2.375rem;
}

.content.links .content__text {
  order: 2;
}
.content.links .content__image {
  order: 1;
}

.content.rechts .content__text {
  order: 1;
}
.content.rechts .content__image {
  order: 2;
}

.content--center {
  text-align: center;
}

.content--section {
  padding: 3.75rem 0;
}

.content--bg-white {
  background: #ffffff;
}

.content--bg-gray {
  background: #fafafa;
}

.content--top-padding {
  padding-top: 2.5rem;
}

.content__title-divider {
  width: 35%;
}

.content__text-divider {
  width: 65%;
}

.content__half-divider {
  width: 45%;
}

.content__image img {
  display: block;
  max-width: 85%;
  height: auto;
  margin: 0 auto;
}

.content__cta .cta__content,
.content__cta .cta__image {
  width: 46%;
}
.content__cta .cta__content img,
.content__cta .cta__image img {
  width: 100%;
  height: auto;
}

.navigation {
  position: fixed;
  background: #ffffff;
  display: flex;
  justify-content: space-between;
  z-index: 999;
}

.navigation__menu-btn {
  cursor: pointer;
  position: relative;
  background: #010b40;
  display: flex;
  align-items: center;
  font-family: "Rubik", sans-serif;
  font-weight: 500;
  font-size: 1rem;
  color: #ffffff;
  border: none;
  padding: 0 3.125rem 0 6.25rem;
  margin: 0;
  height: 5.625rem;
  -webkit-border-radius: 0 0 6px 0;
  -moz-border-radius: 0 0 6px 0;
  border-radius: 0 0 6px 0;
  z-index: 999;
}
.navigation__menu-btn .menu-btn__icon {
  position: absolute;
  -webkit-transition: all 450ms cubic-bezier(0.175, 0.885, 0.32, 1.275);
  -moz-transition: all 450ms cubic-bezier(0.175, 0.885, 0.32, 1.275);
  -o-transition: all 450ms cubic-bezier(0.175, 0.885, 0.32, 1.275);
  transition: all 450ms cubic-bezier(0.175, 0.885, 0.32, 1.275);
}
.navigation__menu-btn .icon-hamburger {
  width: 1.875rem;
  height: auto;
  top: 2.1875rem;
  left: 3.125rem;
}
.navigation__menu-btn .icon-close {
  width: 1.875rem;
  height: auto;
  top: 1.875rem;
  left: 3.125rem;
  transform: scale(0);
}

.navigation__main-menu {
  list-style: none;
  padding: 0;
  margin-left: 2.5rem;
}
.navigation__main-menu li {
  display: inline;
  margin: 0 0.625rem;
}
.navigation__main-menu .main-menu__link {
  font-family: "Rubik", sans-serif;
  font-weight: 500;
  font-size: 1rem;
  color: #010b40;
  display: inline-block;
}
.navigation__main-menu .main-menu__link:after {
  background: #ec6535;
  display: block;
  content: " ";
  width: 0;
  height: 4px;
  margin-top: 0.3125rem;
  -webkit-transition: all 300ms cubic-bezier(0.175, 0.885, 0.32, 1.275);
  -moz-transition: all 300ms cubic-bezier(0.175, 0.885, 0.32, 1.275);
  -o-transition: all 300ms cubic-bezier(0.175, 0.885, 0.32, 1.275);
  transition: all 300ms cubic-bezier(0.175, 0.885, 0.32, 1.275);
}
.navigation__main-menu .main-menu__link:hover:after {
  width: 80%;
}

.navigation__login-btn {
  background: #ec6535;
  display: flex;
  align-items: center;
  font-family: "Rubik", sans-serif;
  font-weight: 500;
  font-size: 1rem;
  color: #ffffff;
  height: 5.625rem;
  padding: 0 2.5rem;
  border: none;
  -webkit-border-radius: 0 0 0 6px;
  -moz-border-radius: 0 0 0 6px;
  border-radius: 0 0 0 6px;
}
.navigation__login-btn img {
  width: 1.5625rem;
  margin-right: 1.25rem;
}

.navigation__overlay {
  position: fixed;
  background: #010b40;
  color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
}

.navigation__title {
  font-family: "Rubik", sans-serif;
  font-weight: 700;
  font-size: 2.5rem;
  text-transform: uppercase;
}

.navigation__hidden-menu {
  list-style: none;
  font-family: "Rubik", sans-serif;
  font-weight: 500;
  font-size: 1.25rem;
  padding: 0;
}
.navigation__hidden-menu li {
  margin: 1.25rem 0;
}
.navigation__hidden-menu .hidden-menu__link {
  -webkit-transition: all 300ms cubic-bezier(0.175, 0.885, 0.32, 1.275);
  -moz-transition: all 300ms cubic-bezier(0.175, 0.885, 0.32, 1.275);
  -o-transition: all 300ms cubic-bezier(0.175, 0.885, 0.32, 1.275);
  transition: all 300ms cubic-bezier(0.175, 0.885, 0.32, 1.275);
  z-index: 9999;
}
.navigation__hidden-menu .hidden-menu__link:hover {
  color: #ec6535;
}
.navigation__hidden-menu .current {
  color: #ec6535;
}

.navigation__submenu {
  font-family: "Rubik", sans-serif;
  font-weight: 400;
  font-size: 1.125rem;
  list-style: none;
  padding: 0 0 0 1.25rem;
}
.navigation__submenu li {
  margin: 0.9375rem;
}

.banner {
  background: #fafafa;
}

.banner__divider {
  width: 50%;
}

.banner__text {
  display: flex;
  justify-content: flex-end;
}

.banner__content {
  font-family: "Karla", sans-serif;
  font-weight: 400;
  font-size: 1.125rem;
  line-height: 2rem;
  color: #010b40;
  max-width: 43.75rem;
  width: 90%;
  padding: 3.125rem;
}

.banner__title {
  font-family: "Rubik", sans-serif;
  font-weight: 700;
  font-size: 2.125rem;
  color: #010b40;
  text-transform: uppercase;
}

.banner__links {
  display: block;
  margin: 2.5rem 0 5rem;
}
.banner__links a {
  margin-right: 1.25rem;
  margin-bottom: 1.25rem;
}

.banner__usp {
  padding: 0;
  list-style: none;
  font-size: 1rem;
}
.banner__usp li {
  display: flex;
  align-items: center;
  margin: 1.25rem 0;
  text-transform: uppercase;
}
.banner__usp li img {
  width: 1.875rem;
  margin-right: 0.9375rem;
}

.banner__image {
  -webkit-border-radius: 50px 0 0 0;
  -moz-border-radius: 50px 0 0 0;
  border-radius: 50px 0 0 0;
  padding-top: 35%;
}

.banner__bus {
  padding: 2.5rem 0;
  width: 50%;
  transform: translateY(5rem);
}
.banner__bus img {
  width: 80%;
  max-width: 43.75rem;
  height: auto;
  transform: scale(1.4);
}

/*
* Cart in menu
*/
#cart__btn {
  cursor: pointer;
  display: flex;
  align-items: center;
  background: #ffffff;
  font-family: "Rubik", sans-serif;
  font-weight: 500;
  font-size: 1rem;
  height: 5.625rem;
  padding: 0 3.125rem;
  border: none;
  -webkit-transition: all 300ms ease-in-out;
  -moz-transition: all 300ms ease-in-out;
  -o-transition: all 300ms ease-in-out;
  transition: all 300ms ease-in-out;
}
#cart__btn img,
#cart__btn svg {
  width: 1.875rem;
  height: auto;
  margin-right: 0.625rem;
}
#cart__btn img path,
#cart__btn svg path {
  -webkit-transition: all 300ms ease-in-out;
  -moz-transition: all 300ms ease-in-out;
  -o-transition: all 300ms ease-in-out;
  transition: all 300ms ease-in-out;
}

.cart-summary {
  position: relative;
}
.cart-summary #cart-overview {
  position: absolute;
  display: block;
  background: #fafafa;
  width: 100%;
  height: calc(100vh - 90px);
  top: 5.625rem;
  right: 0;
  visibility: hidden;
  clip-path: inset(0% 0% 95% 0% round 0 0 0 15%);
  -webkit-transition: all 800ms cubic-bezier(0.175, 0.885, 0.32, 1.275);
  -moz-transition: all 800ms cubic-bezier(0.175, 0.885, 0.32, 1.275);
  -o-transition: all 800ms cubic-bezier(0.175, 0.885, 0.32, 1.275);
  transition: all 800ms cubic-bezier(0.175, 0.885, 0.32, 1.275);
}
.cart-summary .cart-overview__inner {
  display: block;
  position: relative;
  padding: 2.5rem 2.5rem 5rem;
  width: 90%;
  max-width: 23.75rem;
  height: calc(100vh - 90px);
  overflow-y: scroll;
  margin: 0 auto;
}

.cart-overview__products {
  font-family: "Karla", sans-serif;
  font-weight: 400;
  font-size: 1rem;
  color: #010b40;
}

.cart-overview__product {
  position: relative;
  width: 100%;
}
.cart-overview__product .product__close {
  position: absolute;
  background: transparent;
  top: 0.625rem;
  right: 0.625rem;
  padding: 0;
  border: none;
}
.cart-overview__product .product__thumb {
  width: 100%;
  padding: 1.25rem 0;
}
.cart-overview__product .product__thumb img {
  height: 4.375rem;
  width: auto;
  margin: 0 auto;
}
.cart-overview__product .product__title {
  font-family: "Rubik", sans-serif;
  font-weight: 500;
  font-size: 1rem;
  margin-bottom: 0.625rem;
}
.cart-overview__product .product__measurement {
  font-family: "Karla", sans-serif;
  font-weight: 400;
  font-size: 0.875rem;
}
.cart-overview__product .product__amount {
  display: flex;
  font-family: "Rubik", sans-serif;
  font-weight: 500;
  font-size: 1rem;
  color: #010b40;
}
.cart-overview__product .product__amount .amount__number {
  display: inline-block;
  background: #ffffff;
  text-align: center;
  padding: 0.625rem 0.9375rem;
  margin: 0 0.625rem 0 0;
  border: none;
  width: 3.125rem;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  -moz-appearance: textfield;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
  -webkit-box-shadow: 0 0 5px 0px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0 0 5px 0px rgba(0, 0, 0, 0.1);
  box-shadow: 0 0 5px 0px rgba(0, 0, 0, 0.1);
}
.cart-overview__product .product__amount .amount__number::-webkit-outer-spin-button, .cart-overview__product .product__amount .amount__number::-webkit-inner-spin-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin: 0;
}
.cart-overview__product .product__amount .amount__qty {
  cursor: pointer;
  background: #010b40;
  color: #ffffff;
  padding: 0.3125rem 0.9375rem;
  margin: 0 0.9375rem;
  -webkit-box-shadow: 0 0 10px 0px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0 0 10px 0px rgba(0, 0, 0, 0.1);
  box-shadow: 0 0 10px 0px rgba(0, 0, 0, 0.1);
  -webkit-transition: all 300ms ease-in-out;
  -moz-transition: all 300ms ease-in-out;
  -o-transition: all 300ms ease-in-out;
  transition: all 300ms ease-in-out;
  margin: 0 0.625rem 0 0;
  border: none;
}
.cart-overview__product .product__amount .amount__qty:hover {
  background: #ec6535;
}
.cart-overview__product .product__footer {
  display: flex;
  line-height: 1.875rem;
}
.cart-overview__product .product__footer {
  margin: 1.25rem 0;
}
.cart-overview__product .product__price {
  font-family: "Rubik", sans-serif;
  font-weight: 500;
  font-size: 1rem;
  margin-right: 1.25rem;
}
.cart-overview__product .product__tax {
  font-family: "Karla", sans-serif;
  font-weight: 400;
  font-size: 0.875rem;
}

.cart-overview__total {
  display: flex;
  flex-direction: column;
  margin: 3.75rem 0 2.5rem;
}
.cart-overview__total .total__message {
  font-family: "Karla", sans-serif;
  font-weight: 400;
  font-size: 0.875rem;
  color: #ec6535;
}
.cart-overview__total .total__message strong {
  display: inline-block;
}

.cart-overview__total-price {
  font-family: "Rubik", sans-serif;
  font-weight: 700;
  font-size: 1rem;
  text-transform: uppercase;
  color: #010b40;
}

.cart-overview__disclaimer {
  font-family: "Karla", sans-serif;
  font-weight: 400;
  font-size: 0.875rem;
  margin-top: 0.3125rem;
}

.cart-overview__navigation {
  display: flex;
  flex-direction: column;
  text-align: center;
  padding-bottom: 5.625rem;
}
.cart-overview__navigation .btn-underline {
  text-align: left;
}
.cart-overview__navigation a {
  margin: 0.625rem 0;
}

.cart-overview__close {
  cursor: pointer;
  background: #fafafa;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 1.25rem 0;
  border: none;
  position: absolute;
  -webkit-box-shadow: 0 0 20px 0px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0 0 20px 0px rgba(0, 0, 0, 0.1);
  box-shadow: 0 0 20px 0px rgba(0, 0, 0, 0.1);
}
.cart-overview__close img {
  width: 1.5625rem;
  height: auto;
  margin: 0 auto;
}

.cart-page .form input[type=radio] {
  -webkit-appearance: radio;
  -moz-appearance: radio;
  appearance: radio;
}
.cart-page .form label {
  display: inline-block;
  font-family: "Rubik", sans-serif;
  font-weight: 400;
  font-size: 1rem;
  color: #010b40;
}
.cart-page .form .js-calculating-message {
  display: block;
  font-size: 1rem;
  margin-top: 0.625rem;
}
.cart-page #Form_Cart input[type=submit] {
  margin: 1.25rem 0;
}
.cart-page input.you-shall-not-pass {
  cursor: default;
}

.cart-page__heading {
  padding: 1.25rem 0;
  border-bottom: 1px solid #c3c3c3;
}

.cart-page__heading-title {
  font-family: "Rubik", sans-serif;
  font-weight: 700;
  font-size: 1rem;
  color: #010b40;
}

.cart {
  font-family: "Rubik", sans-serif;
  font-weight: 400;
  font-size: 1rem;
  color: #010b40;
  width: 100%;
  border-collapse: collapse;
}
.cart th,
.cart td {
  padding: 1.875rem 1.25rem;
  text-align: left;
}

.cart-page__total {
  width: 100%;
  max-width: 21.875rem;
  margin: 2.5rem 0;
}
.cart-page__total .total__row {
  font-family: "Rubik", sans-serif;
  font-weight: 400;
  font-size: 1rem;
  color: #010b40;
  margin: 1.25rem 0;
}
.cart-page__total .total__price {
  font-weight: 500;
}

.cart__table .productAmountInput {
  border: 1px solid #c3c3c3;
  padding: 0.625rem;
  width: 3.125rem;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  -moz-appearance: textfield;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
  text-align: center;
}
.cart__table .productAmountInput::-webkit-outer-spin-button, .cart__table .productAmountInput::-webkit-inner-spin-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin: 0;
}

.cart__head {
  border-collapse: collapse;
  font-weight: 700;
  border-bottom: 1px solid #c3c3c3;
}

.cart__body {
  border-collapse: collapse;
}
.cart__body tr {
  border-bottom: 1px solid #c3c3c3;
}

.cart__mobile__fix {
  min-width: 180px;
}

.cart__product {
  display: flex;
  align-items: center;
}
.cart__product img {
  max-height: 5.625rem;
  margin-right: 2.5rem;
}

.cart__product-name {
  font-family: "Rubik", sans-serif;
  font-weight: 700;
  font-size: 1.125rem;
}

.cart__qty {
  background: #ffffff;
  padding: 0.3125rem 0.625rem;
  margin: 0 0.9375rem;
  -webkit-box-shadow: 0 0 10px 0px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0 0 10px 0px rgba(0, 0, 0, 0.1);
  box-shadow: 0 0 10px 0px rgba(0, 0, 0, 0.1);
  -webkit-transition: all 300ms ease-in-out;
  -moz-transition: all 300ms ease-in-out;
  -o-transition: all 300ms ease-in-out;
  transition: all 300ms ease-in-out;
}
.cart__qty:hover {
  background: #010b40;
  color: #ffffff;
}

.cart__title {
  font-family: "Rubik", sans-serif;
  font-weight: 400;
  font-size: 1.375rem;
  color: #010b40;
  margin-top: 0.625rem;
}

.cart__delivery-form {
  font-family: "Karla", sans-serif;
  font-weight: 400;
  font-size: 1rem;
  color: #010b40;
  padding-left: 2.5rem;
}
.cart__delivery-form ul {
  list-style: none;
  padding: 0;
}

.cart__mobile {
  position: relative;
  color: #010b40;
  max-width: 18.75rem;
}
.cart__mobile .mobile__remove {
  position: absolute;
  top: 0.625rem;
  right: 0.625rem;
  font-family: "Karla", sans-serif;
  font-weight: 400;
  font-size: 2.375rem;
}
.cart__mobile .mobile__product {
  width: calc(100% - 50px);
}
.cart__mobile .mobile__product img {
  display: block;
  width: 90%;
  max-width: 21.875rem;
  margin: 2.5rem 0;
}
.cart__mobile .mobile__product-title {
  font-family: "Rubik", sans-serif;
  font-weight: 700;
  font-size: 1.375rem;
  line-height: 2.25rem;
  text-transform: uppercase;
}
.cart__mobile .mobile__price {
  display: flex;
  justify-content: space-between;
  width: 100%;
  font-family: "Rubik", sans-serif;
  font-weight: 700;
  font-size: 1.125rem;
  margin: 2.5rem 0;
}
.cart__mobile .productAmountInput {
  border: 1px solid #c3c3c3;
  padding: 0.625rem;
  width: 3.125rem;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  -moz-appearance: textfield;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
  text-align: center;
}
.cart__mobile .productAmountInput::-webkit-outer-spin-button, .cart__mobile .productAmountInput::-webkit-inner-spin-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin: 0;
}
.cart__mobile .mobile__amount {
  font-family: "Rubik", sans-serif;
  font-weight: 700;
  font-size: 1.125rem;
}
.cart__mobile .mobile__amount h4 {
  font-weight: 400;
  margin-bottom: 2.5rem;
}
.cart__mobile .mobile__amount a,
.cart__mobile .mobile__amount span {
  font-family: "Rubik", sans-serif;
  font-weight: 400;
  font-size: 1.25rem;
}
.cart__mobile .mobile__amount .mobile__cty-first {
  margin-left: 0;
}

body.CartStep2 .container {
  background: #fafafa;
}

.cart-proces .form + div .cart-proces__total {
  display: none;
}
.cart-proces #form-wrapper {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
.cart-proces .form-wrapper__left {
  width: 50%;
}
.cart-proces .form-wrapper__right {
  width: 35%;
}
.cart-proces .shippingCalanderTitle {
  margin-top: 3.75rem;
}
.cart-proces #Form_Cart_ShippingCalendar_Holder {
  width: 100%;
  margin-top: 1.25rem;
}
.cart-proces #Form_Cart_ShippingCalendar_Holder #Form_Cart_ShippingCalendar {
  max-width: 21.875rem;
}
.cart-proces #deliveryTimeSlotGroup .fieldholder-small-label,
.cart-proces #Form_Cart_paymentMethod_Holder .fieldholder-small-label {
  font-family: "Rubik", sans-serif;
  font-weight: 500;
  font-size: 1.125rem;
  color: #010b40;
}
.cart-proces #deliveryTimeSlotGroup .fieldgroup-field,
.cart-proces #Form_Cart_paymentMethod_Holder .fieldgroup-field {
  width: 100%;
}
.cart-proces #Form_Cart_deliveryTimeSlot_Holder .optionset {
  margin-top: 2.1875rem;
}
.cart-proces #Form_Cart_deliveryTimeSlot_Holder .optionset li {
  display: flex;
}
.cart-proces #Form_Cart_deliveryTimeSlot_Holder .validation-message {
  position: absolute;
  top: -2.5rem;
  left: 0;
}
.cart-proces #Form_Cart_ShippingCountry_Holder,
.cart-proces #Form_Cart_BillingCountry_Holder {
  display: none;
}
.cart-proces #CallMeGroup,
.cart-proces #Form_Cart_deliveryTimeSlot_Holder {
  margin-top: 1.25rem;
}
.cart-proces #CallMeGroup label,
.cart-proces #Form_Cart_OneAddressForEverything_Holder label {
  color: #262628;
}
.cart-proces #OtherPersonFields {
  border-bottom: solid 1px #c3c3c3;
}
.cart-proces #Form_Cart_OneAddressForEverything_Holder {
  width: 100%;
  margin-top: 0;
  margin-bottom: 1.25rem;
}
.cart-proces #Form_Cart_OrderRemark_Holder {
  margin-bottom: 3.75rem;
}
.cart-proces #Verzendgegevens label.left {
  display: none;
}
.cart-proces #Form_Cart_paymentMethod_ideal + label:after {
  content: " ";
  display: block;
  background-image: url("../images/icons/ideal.svg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: 90%;
  width: 3.125rem;
  height: 3.125rem;
  margin-left: 1.25rem;
}
.cart-proces #Form_Cart_paymentMethod_creditcard + label:after {
  content: " ";
  display: block;
  background-image: url("../images/icons/mastercard.svg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: 90%;
  width: 3.125rem;
  height: 3.125rem;
  margin-left: 1.25rem;
}
.cart-proces #Form_Cart_Same li.val2 input + label:after {
  content: " ";
  background: url("../images/icons/arrow-down-indicator.svg") no-repeat;
  background-size: 100%;
  display: block;
  width: 0.75rem;
  height: 0.5625rem;
  margin: 2px 0 0 15px;
  transform: rotate(-90deg);
  -webkit-transition: all 300ms cubic-bezier(0.175, 0.885, 0.32, 1.275);
  -moz-transition: all 300ms cubic-bezier(0.175, 0.885, 0.32, 1.275);
  -o-transition: all 300ms cubic-bezier(0.175, 0.885, 0.32, 1.275);
  transition: all 300ms cubic-bezier(0.175, 0.885, 0.32, 1.275);
}
.cart-proces #Form_Cart_Same li.val2 input:checked + label:after {
  transform: rotate(0deg);
}
.cart-proces #Contactpersoon .validation-message {
  position: absolute;
  top: -1.875rem;
  left: 0;
}
.cart-proces #Contactpersoon div.fieldgroup-field {
  width: 100%;
  margin-bottom: 0;
}
.cart-proces fieldset > div.field {
  display: inline-block;
  width: 46%;
  margin: 0 2% 1.25rem 0;
}
.cart-proces fieldset > div.field.dropdown {
  width: 100%;
}
.cart-proces fieldset .optionset {
  width: 100%;
  margin-top: 0;
}
.cart-proces fieldset .optionset label {
  color: #262628;
}
.cart-proces fieldset div.CompositeField {
  width: 100%;
}
.cart-proces fieldset div.CompositeField > label {
  font-family: "Rubik", sans-serif;
  font-weight: 500;
  font-size: 1rem;
  color: #010b40;
  margin-top: 1.25rem;
  margin-bottom: 1.875rem;
}
.cart-proces fieldset div.CompositeField div.fieldgroup {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  flex-wrap: wrap;
}
.cart-proces fieldset div.CompositeField div.fieldgroup-field {
  width: 48%;
  margin-bottom: 1.5625rem;
}
.cart-proces .optionset {
  position: relative;
  list-style: none;
  padding: 0;
  margin-top: 0;
  margin-bottom: 0;
}
.cart-proces input + small {
  display: block;
  font-family: "Rubik", sans-serif;
  font-weight: 400;
  font-size: 0.875rem;
  color: #ec6535;
  margin-top: 0.9375rem;
}
.cart-proces .title {
  text-align: left;
}

.cart-proces__title {
  font-family: "Rubik", sans-serif;
  font-weight: 500;
  font-size: 1.5rem;
  color: #010b40;
}

.calander-legend {
  display: block;
  list-style: none;
  padding: 0;
  margin-top: 2.5rem;
}

.calander-legend__item {
  display: flex;
  align-items: center;
  font-family: "Karla", sans-serif;
  font-weight: 400;
  font-size: 0.875rem;
  color: #010b40;
  margin: 0.625rem 0;
}
.calander-legend__item p {
  margin: 0;
}

.calander-legend__color {
  display: block;
  width: 1.25rem;
  min-width: 1.25rem;
  height: 1.25rem;
  margin-right: 0.625rem;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
}

.calander-legend__color--green {
  background: #8dc63f;
}

.calander-legend__color--orange {
  background: #fe7709;
}

.calander-legend__color--red {
  background: #be0000;
}

.cart-proces__total {
  font-family: "Rubik", sans-serif;
  font-weight: 500;
  font-size: 1rem;
  line-height: 2.875rem;
  color: #010b40;
  width: 100%;
  max-width: 34.375rem;
}
.cart-proces__total hr {
  margin: 1.25rem 0;
}
.cart-proces__total .total__prop {
  display: block;
  width: 50%;
}
.cart-proces__total .total__price {
  display: block;
  width: 50%;
  font-size: 1.25rem;
}
.cart-proces__total .total__delivery {
  display: block;
  width: 50%;
}

.flatpickr-calendar.open {
  z-index: 10 !important;
}

.flatpickr-calendar {
  position: relative;
  display: block;
  font-family: "Karla", sans-serif;
  font-weight: 400;
  font-size: 0.875rem;
  border: none;
  width: 21.875rem;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1) !important;
  top: 0 !important;
  left: 0 !important;
  visibility: visible !important;
  opacity: 1 !important;
  margin-top: 1.25rem;
}
.flatpickr-calendar .flatpickr-months,
.flatpickr-calendar .flatpickr-current-month {
  font-family: "Rubik", sans-serif;
  font-weight: 400;
  font-size: 1rem;
  color: #010b40;
  height: 3.75rem;
  padding-top: 0.625rem;
}
.flatpickr-calendar .flatpickr-next-month,
.flatpickr-calendar .flatpickr-prev-month {
  top: 0.625rem !important;
}
.flatpickr-calendar .flatpickr-weekday {
  font-family: "Rubik", sans-serif;
  font-weight: 700;
  font-size: 0.875rem;
  color: #010b40;
}
.flatpickr-calendar .flatpickr-day {
  color: #8dc63f;
}
.flatpickr-calendar .flatpickr-day:hover {
  background: #010b40;
  color: #ffffff;
  border-color: #010b40;
}
.flatpickr-calendar .flatpickr-day.flatpickr-disabled,
.flatpickr-calendar .flatpickr-day.redDay,
.flatpickr-calendar .flatpickr-day.prevMonthDay {
  color: #be0000;
  background: transparent;
  border: none;
}
.flatpickr-calendar .flatpickr-day.orangeDay {
  color: #fe7709;
}
.flatpickr-calendar .flatpickr-day.today {
  background: #eeeeee;
  color: #be0000;
  font-weight: 700;
  border: none;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  border-radius: 10px;
}
.flatpickr-calendar .flatpickr-day.nextMonthDay,
.flatpickr-calendar .flatpickr-day.prevMonthDay {
  opacity: 0;
}
.flatpickr-calendar .flatpickr-day.nextMonthDay:hover,
.flatpickr-calendar .flatpickr-day.prevMonthDay:hover {
  background: #010b40;
  color: #ffffff;
}
.flatpickr-calendar .flatpickr-day.selected {
  background: #010b40;
  border: none;
  color: #ffffff;
}
.flatpickr-calendar .flatpickr-day.selected:hover {
  background: #ec6535;
}
.flatpickr-calendar .flatpickr-innerContainer {
  padding: 1.25rem;
}

.flatpickr-months .flatpickr-month {
  height: 3.75rem !important;
}

.shop-usp__list {
  list-style: none;
  padding: 0;
  display: flex;
  flex-wrap: wrap;
}

.shop-usp__usp {
  display: flex;
  align-items: center;
  font-family: "Karla", sans-serif;
  font-weight: 700;
  font-size: 0.875rem;
  color: #010b40;
  margin: 1.25rem 3.125rem 1.25rem 0;
}
.shop-usp__usp img {
  margin-right: 1.25rem;
  width: 2.25rem;
  height: auto;
}

.dashboard .form label {
  display: inline-block;
  font-family: "Rubik", sans-serif;
  font-weight: 500;
  font-size: 1rem;
}
.dashboard form .description {
  display: none;
}
.dashboard .dashboard__orders {
  overflow-x: scroll;
  width: 100%;
}

.dashboard__orders .cart__product img {
  width: 3.75rem;
}

.dashboard__header {
  display: block;
  border-bottom: 1px solid #c3c3c3;
  height: 3.75rem;
}

.dashboard__title {
  margin: 0.625rem 0;
  font-size: 1.875rem;
}

.dashboard__name {
  color: #ec6535;
}

.dashboard__menu {
  padding: 0;
  list-style: none;
}
.dashboard__menu li {
  margin: 0.625rem 0;
}
.dashboard__menu .menu__item {
  font-family: "Rubik", sans-serif;
  font-weight: 500;
  font-size: 1rem;
  color: #010b40;
  text-transform: uppercase;
}

.products {
  padding: 1.25rem 0 3.125rem;
}

.products__holder {
  padding: 5rem 0;
}

.js-products__slider {
  position: relative;
}
.js-products__slider .slider__controls {
  cursor: pointer;
  position: absolute;
  display: block;
  background-color: transparent;
  background-repeat: no-repeat;
  top: 40%;
  height: 1.6875rem;
  width: 0.875rem;
  border: none;
  z-index: 99;
  -webkit-transition: all 300ms ease-in-out;
  -moz-transition: all 300ms ease-in-out;
  -o-transition: all 300ms ease-in-out;
  transition: all 300ms ease-in-out;
}
.js-products__slider button[aria-disabled=true] {
  opacity: 0;
}
.js-products__slider .slider__prev {
  background-image: url("../images/icons/arrow-blue-left.svg");
  left: 0;
}
.js-products__slider .slider__next {
  background-image: url("../images/icons/arrow-blue-right.svg");
  right: 0;
}
.js-products__slider .slick-list {
  width: 90%;
  padding: 4.375rem 2.5rem;
  margin: 0 auto;
}
.js-products__slider .slick-slide {
  margin: 0 1.25rem;
}
.js-products__slider .slick-dots {
  list-style: none;
  position: absolute;
  display: flex;
  justify-content: center;
  bottom: 0.625rem;
  width: 100%;
  padding: 0;
}
.js-products__slider .slick-dots button {
  cursor: pointer;
  background: #010b40;
  text-indent: -9999;
  display: block;
  width: 0.625rem;
  height: 0.625rem;
  padding: 0;
  overflow: hidden;
  -webkit-border-radius: 50px;
  -moz-border-radius: 50px;
  border-radius: 50px;
  -webkit-transition: all 300ms cubic-bezier(0.175, 0.885, 0.32, 1.275);
  -moz-transition: all 300ms cubic-bezier(0.175, 0.885, 0.32, 1.275);
  -o-transition: all 300ms cubic-bezier(0.175, 0.885, 0.32, 1.275);
  transition: all 300ms cubic-bezier(0.175, 0.885, 0.32, 1.275);
  margin: 0 0.3125rem;
  border: none;
}
.js-products__slider .slick-dots .slick-active button {
  width: 1.5625rem;
}

.products__type-holder {
  width: 48%;
}
.products__type-holder .products__item--main {
  display: none;
  width: 90%;
  min-height: 35rem;
}
.products__type-holder .products__item--main.active {
  display: block;
}

.js-cubes-slider {
  width: 50%;
}
.js-cubes-slider .slick-list,
.js-cubes-slider .slick-list div,
.js-cubes-slider .slick-list figure,
.js-cubes-slider .slick-track {
  height: 100%;
}
.js-cubes-slider .slick-slide {
  padding: 2.1875rem;
}

.js-product-page-slider {
  position: relative;
}
.js-product-page-slider .slick-list,
.js-product-page-slider .slick-list div,
.js-product-page-slider .slick-list figure,
.js-product-page-slider .slick-track {
  height: 100%;
}
.js-product-page-slider .slider__controls {
  cursor: pointer;
  position: absolute;
  display: block;
  background-color: transparent;
  background-repeat: no-repeat;
  background-size: 90%;
  background-position: center center;
  bottom: 0.9375rem;
  height: 1.6875rem;
  width: 0.875rem;
  border: none;
  z-index: 99;
  -webkit-transition: all 300ms ease-in-out;
  -moz-transition: all 300ms ease-in-out;
  -o-transition: all 300ms ease-in-out;
  transition: all 300ms ease-in-out;
}
.js-product-page-slider .slider__prev {
  background-image: url("../images/icons/arrow-blue-left.svg");
  left: 1.25rem;
}
.js-product-page-slider .slider__next {
  background-image: url("../images/icons/arrow-blue-right.svg");
  right: 1.25rem;
}
.js-product-page-slider .slick-dots {
  list-style: none;
  position: absolute;
  display: flex;
  justify-content: center;
  bottom: 0.625rem;
  width: 100%;
  padding: 0;
}
.js-product-page-slider .slick-dots button {
  cursor: pointer;
  background: #010b40;
  text-indent: -9999;
  display: block;
  width: 0.625rem;
  height: 0.625rem;
  padding: 0;
  overflow: hidden;
  -webkit-border-radius: 50px;
  -moz-border-radius: 50px;
  border-radius: 50px;
  -webkit-transition: all 300ms cubic-bezier(0.175, 0.885, 0.32, 1.275);
  -moz-transition: all 300ms cubic-bezier(0.175, 0.885, 0.32, 1.275);
  -o-transition: all 300ms cubic-bezier(0.175, 0.885, 0.32, 1.275);
  transition: all 300ms cubic-bezier(0.175, 0.885, 0.32, 1.275);
  margin: 0 0.3125rem;
  border: none;
}
.js-product-page-slider .slick-dots .slick-active button {
  width: 1.5625rem;
}

.products__item {
  display: block;
  position: relative;
  background: #ffffff;
  color: #010b40;
  padding: 1.875rem;
  max-width: 25rem;
  margin: 0 auto;
  -webkit-border-radius: 6px;
  -moz-border-radius: 6px;
  border-radius: 6px;
  -webkit-box-shadow: 0 0 35px 0px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0 0 35px 0px rgba(0, 0, 0, 0.1);
  box-shadow: 0 0 35px 0px rgba(0, 0, 0, 0.1);
  overflow: visible;
}
.products__item .item__title {
  font-family: "Rubik", sans-serif;
  font-weight: 700;
  font-size: 1.25rem;
  text-transform: uppercase;
  margin: 0 0 0.625rem;
}
.products__item .item__measurement {
  font-family: "Karla", sans-serif;
  font-weight: 400;
  font-size: 0.875rem;
}
.products__item .item__thumb {
  width: 100%;
  margin: 2.5rem 0;
}
.products__item .item__thumb img {
  display: block;
  width: auto;
  height: 8.75rem;
  margin: 0 auto;
}
.products__item .item__weights {
  display: block;
  margin: 3.125rem 0 1.25rem;
}
.products__item .item__weight-option {
  cursor: pointer;
  margin-right: 0.625rem;
  margin-bottom: 0.625rem;
  display: inline-block;
}
.products__item .item__weight-option span {
  display: inline-block;
  background: #ffffff;
  font-family: "Karla", sans-serif;
  font-weight: 400;
  font-size: 1rem;
  color: #010b40;
  padding: 0.9375rem 1.25rem;
  -webkit-box-shadow: 0 0 10px 0px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0 0 10px 0px rgba(0, 0, 0, 0.1);
  box-shadow: 0 0 10px 0px rgba(0, 0, 0, 0.1);
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
  border: 2px solid transparent;
  -webkit-transition: all 300ms ease-in-out;
  -moz-transition: all 300ms ease-in-out;
  -o-transition: all 300ms ease-in-out;
  transition: all 300ms ease-in-out;
}
.products__item .tablinks.active span {
  border-color: #010b40;
}
.products__item .item__specs {
  font-family: "Karla", sans-serif;
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.5rem;
}
.products__item .item__footer {
  padding: 0.625rem 0 0;
  position: relative;
  margin-bottom: 30px;
  padding-bottom: 20px;
}
.products__item .item__price {
  font-family: "Rubik", sans-serif;
  font-weight: 700;
  font-size: 1.25rem;
}
.products__item .item__tax {
  font-family: "Karla", sans-serif;
  font-weight: 400;
  font-size: 0.875rem;
}
.products__item .item__add {
  cursor: pointer;
  position: absolute;
  background: #010b40;
  font-family: "Rubik", sans-serif;
  font-weight: 500;
  font-size: 2rem;
  color: #ffffff;
  padding: 0.625rem 1.875rem;
  -webkit-border-radius: 6px 0 0 0;
  -moz-border-radius: 6px 0 0 0;
  border-radius: 6px 0 0 0;
  right: 0;
  bottom: 0;
  border: none;
}

.products__item--main {
  width: 45%;
  max-width: initial;
}

.products__item--small {
  max-width: 21.875rem;
}

.product-info {
  background-color: #fafafa;
}

.product-info__image {
  width: 60%;
}
.product-info__image img {
  width: 60%;
  margin: 0 auto;
  transform: scale(1.2);
}

.product-info__content {
  width: 40%;
}

.product {
  flex: 1;
  height: 100%;
}
.product .btn {
  margin-right: 1.25rem;
}

.product__image {
  display: flex !important;
  justify-content: center;
  align-items: center;
  background: #fafafa;
  -webkit-border-radius: 0 50px 0 0;
  -moz-border-radius: 0 50px 0 0;
  border-radius: 0 50px 0 0;
  overflow: hidden;
}
.product__image img {
  width: 28.125rem;
  max-width: 90%;
}

.product__content {
  font-family: "Karla", sans-serif;
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.5rem;
  color: #010b40;
  padding: 2.5rem 3.75rem;
}

.product__weights {
  display: flex;
  list-style: none;
  padding: 0;
  margin: 2.5rem 0;
}

.product__weight-option {
  display: block;
  background: #ffffff;
  font-family: "Karla", sans-serif;
  font-weight: 400;
  font-size: 1rem;
  color: #010b40;
  padding: 0.9375rem 1.25rem;
  margin-right: 1.25rem;
  -webkit-box-shadow: 0 0 10px 0px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0 0 10px 0px rgba(0, 0, 0, 0.1);
  box-shadow: 0 0 10px 0px rgba(0, 0, 0, 0.1);
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
  border: 2px solid transparent;
  -webkit-transition: all 300ms ease-in-out;
  -moz-transition: all 300ms ease-in-out;
  -o-transition: all 300ms ease-in-out;
  transition: all 300ms ease-in-out;
}
.product__weight-option:hover {
  border: 2px solid #010b40;
}

.product__weight-option.active {
  border-color: #010b40;
}

.product__price {
  display: inline-block;
  font-family: "Rubik", sans-serif;
  font-weight: 700;
  font-size: 1.25rem;
  margin-right: 1.25rem;
}

.info__staffel {
  margin-left: 5px;
  border-radius: 50%;
  font-size: 20px;
  color: #fff;
  text-align: center;
  background: #010b40;
  cursor: pointer;
  padding-left: 10px;
  padding-right: 10px;
}

.staffel__discount {
  border-radius: 6px;
  font-family: "Rubik";
  font-style: normal;
  font-size: 16px;
  color: white;
  width: 100%;
  padding: 10px 5px;
  margin-top: 15px;
  cursor: auto;
  z-index: 10;
  background-color: #010b40;
  border: 1px solid black;
}
.staffel__discount table tr td {
  text-align: center;
}
.staffel__discount table {
  width: 100%;
  border-collapse: collapse;
}
.staffel__discount table td, .staffel__discount table th {
  padding: 3px;
}
.staffel__discount.variation {
  width: 350px;
}

.cta-calculating__content {
  font-family: "Karla", sans-serif;
  font-weight: 400;
  font-size: 1rem;
  color: #010b40;
  line-height: 1.5rem;
  width: 35%;
}

.cta-calculating__image {
  width: 65%;
}
.cta-calculating__image img {
  width: 100%;
  height: auto;
}

.modal {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  z-index: 10;
  opacity: 0;
  visibility: hidden;
  transition: all 0.55s cubic-bezier(0.25, 0.8, 0.25, 1);
  background-color: rgba(0, 0, 0, 0.5);
  font-family: "Karla", sans-serif;
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.5rem;
}
.modal a {
  color: #ec6535;
}
.modal.is-active {
  opacity: 1;
  display: flex;
  justify-content: center;
  pointer-events: all;
  align-items: center;
  visibility: visible;
}
.modal.not-active {
  opacity: 0;
  pointer-events: none;
  visibility: hidden;
  display: none;
}

.modal__content {
  background-color: #ffffff;
  padding: 20px;
  width: 80vw;
  margin-top: 100px;
  margin-bottom: 30px;
  overflow-y: auto;
  border-radius: 10px;
  max-height: 80vh;
  max-width: fit-content;
}
@media screen and (min-width: 1024px) {
  .modal__content {
    padding: 40px 60px;
  }
}

span.modal__close {
  color: #ec6535;
  margin-top: 40px;
  display: block;
  font-family: "Karla", sans-serif;
  font-weight: 600;
  font-size: 1.125rem;
  cursor: pointer;
}

.deliverycosts-calculating .form input[type=text] {
  display: inline-block;
  font-family: "Karla", sans-serif;
  font-weight: 400;
  font-size: 1rem;
  width: 15rem;
}
.deliverycosts-calculating .form input[type=submit] {
  background-color: #010b40;
  background-image: url("../images/icons/arrow-right-white.svg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: 0.625rem;
  display: inline-block;
  width: 3.125rem;
  height: 3.125rem;
  margin-left: -0.625rem;
  border: none;
  -webkit-border-radius: 0 3px 3px 0;
  -moz-border-radius: 0 3px 3px 0;
  border-radius: 0 3px 3px 0;
}

#deliverycostform {
  position: relative;
}

.deliverycosts-calculating__form {
  position: relative;
}

.deliverycosts__popup {
  position: absolute;
  top: 5rem;
  display: block;
  font-family: "Karla", sans-serif;
  font-weight: 700;
  font-size: 0.875rem;
  color: #ffffff;
  background: #010b40;
  padding: 0.9375rem 2.5rem;
  -webkit-border-radius: 6px;
  -moz-border-radius: 6px;
  border-radius: 6px;
  -webkit-box-shadow: 0 0 10px 0px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0 0 10px 0px rgba(0, 0, 0, 0.1);
  box-shadow: 0 0 10px 0px rgba(0, 0, 0, 0.1);
}
.deliverycosts__popup a {
  color: #ec6535;
}
.deliverycosts__popup a:hover {
  text-decoration: underline;
}
.deliverycosts__popup strong {
  font-family: "Rubik", sans-serif;
  font-weight: 700;
  font-size: 1rem;
}
.deliverycosts__popup:before {
  content: " ";
  position: absolute;
  top: -20px;
  left: 20%;
  margin-left: -20px;
  border-width: 20px;
  border-style: solid;
  border-color: transparent transparent transparent #010b40;
}

.selling-points {
  display: flex;
  flex: 1;
}

.selling-points__map,
.selling-points__content {
  width: 50%;
}

.selling-points__map {
  position: relative;
  -webkit-border-radius: 0 50px 0 0;
  -moz-border-radius: 0 50px 0 0;
  border-radius: 0 50px 0 0;
  overflow: hidden;
}

#selling-point-map {
  display: block;
  width: 100%;
  height: 100%;
}

.selling-points__cta-link {
  background: #ffffff;
  position: absolute;
  display: inline-block;
  text-align: center;
  width: 20rem;
  bottom: 0;
  left: 50%;
  margin-left: -10rem;
  padding: 1.25rem 0;
  -webkit-box-shadow: 0 0 10px 0px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0 0 10px 0px rgba(0, 0, 0, 0.1);
  box-shadow: 0 0 10px 0px rgba(0, 0, 0, 0.1);
  -webkit-transition: all 300ms cubic-bezier(0.175, 0.885, 0.32, 1.275);
  -moz-transition: all 300ms cubic-bezier(0.175, 0.885, 0.32, 1.275);
  -o-transition: all 300ms cubic-bezier(0.175, 0.885, 0.32, 1.275);
  transition: all 300ms cubic-bezier(0.175, 0.885, 0.32, 1.275);
}
.selling-points__cta-link .cta-link__title {
  font-family: "Rubik", sans-serif;
  font-weight: 700;
  font-size: 1.25rem;
  color: #010b40;
}
.selling-points__cta-link .cta-link__text {
  font-family: "Karla", sans-serif;
  font-weight: 400;
  font-size: 0.875rem;
  margin-bottom: 0;
}
.selling-points__cta-link:hover {
  padding: 1.25rem 0 3.125rem;
}

.selling-points__content {
  padding: 3.125rem 0 5rem 3.75rem;
}
.selling-points__content .content {
  width: 90%;
  max-width: 37.5rem;
}

.selling-points__form {
  width: 90%;
  max-width: 25rem;
}
.selling-points__form input {
  margin-top: 1.25rem;
  max-width: 18.75rem;
}

.selling-points__sub-title {
  font-family: "Rubik", sans-serif;
  font-weight: 700;
  font-size: 1.125rem;
  text-transform: uppercase;
  color: #010b40;
}

.footer {
  background: #010b40;
  font-family: "Karla", sans-serif;
  font-weight: 400;
  font-size: 1rem;
  color: #ffffff;
  line-height: 1.625rem;
  padding: 2.5rem 0;
}

.footer__section {
  width: 22%;
}

.footer__section--main {
  width: 25%;
}

.footer__main-title {
  font-family: "Rubik", sans-serif;
  font-weight: 700;
  font-size: 1.75rem;
}

.footer__subtitle {
  font-family: "Rubik", sans-serif;
  font-weight: 700;
  font-size: 1rem;
}

.footer__main-title,
.footer__subtitle {
  text-transform: uppercase;
  line-height: 3.125rem;
  margin-top: 0;
}

.footer__label {
  width: 3.75rem;
  height: auto;
}

.footer__meta {
  background-color: #ffffff;
  font-family: "Karla", sans-serif;
  font-weight: 400;
  font-size: 0.875rem;
  color: #010b40;
  padding: 1.25rem 0;
}

.footer__contact-trigger {
  color: #ec6535;
}

.footer__menu {
  list-style: none;
  padding: 0;
}
.footer__menu a {
  -webkit-transition: all 300ms ease-in-out;
  -moz-transition: all 300ms ease-in-out;
  -o-transition: all 300ms ease-in-out;
  transition: all 300ms ease-in-out;
}
.footer__menu a:hover {
  color: #ec6535;
}

/*	5	State
-----------------------------------------*/
.js-cart-overview-hidden {
  visibility: hidden;
  clip-path: inset(0% 0% 95% 0% round 0 0 0 15%);
  -webkit-transition: all 800ms cubic-bezier(0.175, 0.885, 0.32, 1.275);
  -moz-transition: all 800ms cubic-bezier(0.175, 0.885, 0.32, 1.275);
  -o-transition: all 800ms cubic-bezier(0.175, 0.885, 0.32, 1.275);
  transition: all 800ms cubic-bezier(0.175, 0.885, 0.32, 1.275);
}

#cart__btn.js-cart-toggle--active {
  background: #fafafa !important;
}

.cart-summary .js-cart-overview-open {
  visibility: visible !important;
  clip-path: inset(0% 0% 0% 0% round 0) !important;
}

.js-hidden-menu-container {
  visibility: hidden;
  clip-path: inset(0% 95% 95% 0% round 0 0 100px 0);
  -webkit-transition: all 1000ms cubic-bezier(0.175, 0.885, 0.32, 1.275);
  -moz-transition: all 1000ms cubic-bezier(0.175, 0.885, 0.32, 1.275);
  -o-transition: all 1000ms cubic-bezier(0.175, 0.885, 0.32, 1.275);
  transition: all 1000ms cubic-bezier(0.175, 0.885, 0.32, 1.275);
}

.js-hidden-menu-is-visible {
  visibility: visible;
  clip-path: inset(0% 0% 0% 0% round 0);
}

.js-hidden-menu-shop-style {
  background: transparent !important;
  color: #ffffff;
}
.js-hidden-menu-shop-style svg path {
  fill: #ffffff;
}

.js-btn-active .icon-hamburger {
  transform: scale(0);
}
.js-btn-active .icon-close {
  transform: scale(1);
}

.js-cart-disabled {
  visibility: hidden !important;
  opacity: 0 !important;
  z-index: 0;
}
.js-cart-disabled .cart-overview__inner {
  display: none;
}

.js-cta-popup {
  visibility: hidden;
  opacity: 0;
  -webkit-transition: all 300ms ease-in-out;
  -moz-transition: all 300ms ease-in-out;
  -o-transition: all 300ms ease-in-out;
  transition: all 300ms ease-in-out;
}

.js-cta-popup-show {
  visibility: visible;
  opacity: 1;
}

#Form_Cart_OneAddressForEverything_Holder input[type=checkbox] {
  position: absolute;
  visibility: hidden;
}
#Form_Cart_OneAddressForEverything_Holder label {
  cursor: pointer;
  display: flex;
  align-items: center;
}
#Form_Cart_OneAddressForEverything_Holder input[type=checkbox] + label:before {
  content: " ";
  display: block;
  background-image: url("../images/icons/checkbox-initial.svg");
  width: 1.875rem;
  height: 1.875rem;
  margin-right: 1.25rem;
}
#Form_Cart_OneAddressForEverything_Holder input[type=checkbox]:checked + label:before {
  content: "";
  background-image: url("../images/icons/checkbox-checked.svg");
}

#CallMeGroup {
  width: 100%;
}
#CallMeGroup .fieldgroup-field {
  width: 100%;
  margin-bottom: 0;
}
#CallMeGroup input[type=checkbox] {
  position: absolute;
  visibility: hidden;
}
#CallMeGroup label {
  cursor: pointer;
  display: flex;
  align-items: center;
}
#CallMeGroup input[type=checkbox] + label:before {
  content: " ";
  display: block;
  background-image: url("../images/icons/checkbox-initial.svg");
  width: 1.875rem;
  height: 1.875rem;
  margin-right: 1.25rem;
}
#CallMeGroup input[type=checkbox]:checked + label:before {
  content: "";
  background-image: url("../images/icons/checkbox-checked.svg");
}

#Contactpersoon input[type=radio],
#deliveryTimeSlotGroup input[type=radio],
#Form_Cart_paymentMethod_Holder input[type=radio] {
  position: absolute;
  visibility: hidden;
}
#Contactpersoon label,
#deliveryTimeSlotGroup label,
#Form_Cart_paymentMethod_Holder label {
  cursor: pointer;
  display: flex;
  align-items: center;
}
#Contactpersoon input[type=radio] + .error + label:before,
#deliveryTimeSlotGroup input[type=radio] + .error + label:before,
#Form_Cart_paymentMethod_Holder input[type=radio] + .error + label:before {
  content: " ";
  display: block;
  background-image: url("../images/icons/radiobutton-initial.svg");
  background-repeat: no-repeat;
  background-size: 100%;
  width: 1.875rem;
  min-width: 1.875rem;
  height: 1.875rem;
  margin-right: 1.25rem;
}
#Contactpersoon input[type=radio] + label:before,
#deliveryTimeSlotGroup input[type=radio] + label:before,
#Form_Cart_paymentMethod_Holder input[type=radio] + label:before {
  content: " ";
  display: block;
  background-image: url("../images/icons/radiobutton-initial.svg");
  background-repeat: no-repeat;
  background-size: 100%;
  width: 1.875rem;
  min-width: 1.875rem;
  height: 1.875rem;
  margin-right: 1.25rem;
}
#Contactpersoon input[type=radio]:checked + label:before,
#deliveryTimeSlotGroup input[type=radio]:checked + label:before,
#Form_Cart_paymentMethod_Holder input[type=radio]:checked + label:before {
  content: "";
  background-image: url("../images/icons/radiobutton-checked.svg");
}
#Contactpersoon input[type=radio]:checked + .error + label:before,
#deliveryTimeSlotGroup input[type=radio]:checked + .error + label:before,
#Form_Cart_paymentMethod_Holder input[type=radio]:checked + .error + label:before {
  content: "";
  background-image: url("../images/icons/radiobutton-checked.svg");
}
#Contactpersoon input[type=radio] + label.error:before,
#deliveryTimeSlotGroup input[type=radio] + label.error:before,
#Form_Cart_paymentMethod_Holder input[type=radio] + label.error:before {
  content: " ";
  display: none;
}

/*	6	Breakoints
-----------------------------------------*/
/* min-width */
@media screen and (min-width: 75rem) {
  .banner__image {
    padding: 0;
    min-height: 37.5rem;
  }
}
/* max-width */
@media screen and (max-width: 73.75rem) {
  .navigation__main-menu {
    display: none;
  }
  .product .btn {
    margin: 0.625rem 0.625rem 0.625rem 0;
  }
  .product__content {
    font-size: 1rem;
    line-height: 1.5rem;
  }
  .cart-proces .form-wrapper__right {
    width: 40%;
  }
}
@media screen and (max-width: 60rem) {
  .banner__divider,
.banner__bus {
    width: 100%;
  }
  .banner__bus img {
    display: block;
    margin: 0 auto;
    width: 70%;
    max-width: 37.5rem;
  }
  .banner__text {
    justify-content: center;
  }
  .banner__content {
    max-width: initial;
    padding-bottom: 0;
  }
  .banner__image {
    margin-top: 2.5rem;
    padding-top: 45%;
  }
  .products__item {
    width: 90%;
    max-width: initial;
    margin-bottom: 3.125rem;
  }
  .cta-calculating__content {
    text-align: center;
    width: 100%;
  }
  .cta-calculating__image {
    display: block;
    width: 90%;
    margin: 6.25rem auto 0;
  }
  .product-info {
    padding-bottom: 2.5rem;
  }
  .product__content {
    font-size: 1rem;
    line-height: 1.5rem;
  }
  .product-info__image,
.product-info__content {
    width: 100%;
  }
  .product-info__image img,
.product-info__content img {
    display: block;
    max-width: 20rem;
  }
  .product-info__content {
    margin-top: 2.5rem;
    text-align: center;
  }
  .cart-proces .form-wrapper__left,
.cart-proces .form-wrapper__right {
    width: 100%;
  }
  .cart-proces .form-wrapper__right {
    display: flex;
    flex-direction: column;
  }
  .cart-proces .form-wrapper__right .cart-proces__total {
    order: 5;
    margin-top: 0;
    margin-bottom: 1.875rem;
  }
  .cart-proces .form-wrapper__right .shippingCalanderTitle {
    order: 1;
    margin-top: 0;
  }
  .cart-proces .form-wrapper__right #Form_Cart_ShippingCalendar_Holder {
    order: 2;
    margin-bottom: 0;
  }
  .cart-proces .form-wrapper__right #deliveryTimeSlotGroup {
    order: 3;
  }
  .cart-proces .form-wrapper__right #Form_Cart_paymentMethod_Holder {
    order: 4;
    margin-bottom: 1.875rem;
  }
  .selling-points {
    flex-wrap: wrap;
  }
  .selling-points__map {
    width: 100%;
    height: 37.5rem;
  }
  .selling-points__content {
    width: 100%;
    padding: 2.5rem 5% 4.375rem;
  }
  .selling-points__cta-link {
    width: 17.5rem;
    margin-left: -8.75rem;
  }
  .selling-points__cta-link .cta-link__title {
    font-family: "Rubik", sans-serif;
    font-weight: 700;
    font-size: 1rem;
  }
  .selling-points__cta-link .cta-link__text {
    font-family: "Karla", sans-serif;
    font-weight: 400;
    font-size: 0.75rem;
  }
  .footer__section,
.footer__section--main {
    width: 48%;
    margin-bottom: 1.25rem;
  }
}
@media screen and (max-width: 53.75rem) {
  .products__type-holder {
    width: 100%;
  }
}
@media screen and (max-width: 47.5rem) {
  .l-flex__c60,
.l-flex__c50,
.l-flex__c40 {
    width: 100%;
  }
  .container {
    padding-top: 4.375rem;
  }
  .login__half {
    width: 100%;
  }
  .navigation__menu-btn,
.navigation__login-btn,
#cart__btn {
    font-size: 0.875rem;
    height: 4.375rem;
  }
  .navigation__menu-btn {
    padding: 0 2.1875rem 0 5rem;
  }
  .navigation__menu-btn .icon-hamburger {
    width: 1.25rem;
    top: 1.875rem;
    left: 1.875rem;
  }
  .navigation__menu-btn .icon-close {
    width: 1.25rem;
    top: 1.5625rem;
    left: 1.875rem;
  }
  .navigation__login-btn img {
    width: 1.25rem;
  }
  .content__half-divider {
    width: 100%;
  }
  .content__text {
    order: 1;
  }
  .content__image {
    order: 2 !important;
    margin: 3.125rem 0 0;
  }
  .cart__btn img,
.cart__btn svg {
    width: 1.5625rem;
    height: auto;
  }
  .cart-summary #cart-overview {
    top: 4.375rem;
    height: calc(100vh - 70px);
  }
  .cart__mobile {
    display: block;
  }
  .cart__delivery-form {
    padding-left: 0;
    margin-top: 2.5rem;
  }
  .cart-proces #Form_Cart_ShippingCalendar_Holder {
    height: 38.75rem;
  }
  .btn,
.form input[type=submit] {
    padding: 0.625rem 1.25rem;
  }
  .title--sub {
    font-size: 1.375rem;
  }
  .createAccount {
    max-width: initial;
    width: 100%;
    margin: 3.125rem 0 0 0;
    padding-left: 0;
  }
}
@media screen and (max-width: 41.25rem) {
  .content__title-divider,
.content__text-divider {
    width: 100%;
  }
  .content--top-padding {
    padding-top: 0;
  }
  .content--section {
    padding: 2.5rem 0;
  }
  .content__cta .cta__content,
.content__cta .cta__image {
    width: 100%;
  }
  .content__cta .cta__image {
    order: 1;
  }
  .content__cta .cta__content {
    order: 2;
  }
  .cart-proces .cart__product img {
    display: none;
  }
}
@media screen and (max-width: 35rem) {
  .l-top-space--large {
    margin-top: 5.625rem;
  }
  .navigation__menu-btn {
    padding: 0 0 0 5rem;
  }
  .navigation__menu-btn span {
    display: none;
  }
  #cart__btn {
    padding: 0 1.875rem;
  }
  .cart-summary #cart-overview {
    width: 100vw;
  }
  .cart-summary .cart-overview {
    width: 100vw;
  }
  .banner__image {
    padding-top: 65%;
  }
  .banner__title {
    font-size: 1.625rem;
  }
  .banner__content {
    font-size: 1rem;
    line-height: 1.5rem;
    padding: 1.25rem;
  }
  .banner__links {
    margin: 1.875rem 0 2.5rem;
  }
  .banner__links a {
    margin-bottom: 1.25rem;
  }
  .products__holder {
    padding: 2.5rem 0 1.25rem;
  }
  .products__item .item__weight-option {
    display: inline-block;
    margin: 0.625rem 0.625rem 0.625rem 0;
  }
  .js-products__slider .slick-list {
    padding: 2.5rem 0;
  }
  .selling-points__map {
    height: 25rem;
  }
  .selling-points__content {
    padding: 2.5rem 5% 4.375rem;
  }
  .selling-points__content .content {
    width: 100%;
  }
  .cart-proces #Form_Cart_ShippingCalendar_Holder {
    height: 40rem;
  }
  .cart-proces fieldset div.CompositeField div.fieldgroup-field {
    width: 100%;
  }
  .footer__section,
.footer__section--main {
    width: 100%;
  }
  .footer__meta .l-flex {
    align-items: center;
    flex-direction: column;
  }
  .footer__meta .l-flex span {
    margin: 0.3125rem;
  }
}
@media screen and (max-width: 29.375rem) {
  .banner__image {
    padding-top: 75%;
  }
  .content--section {
    padding: 1.875rem 0;
  }
  .form fieldset.field {
    margin-bottom: 0;
  }
  .form .userformsgroup .middleColumn {
    flex-wrap: wrap;
  }
  .form .userformsgroup .middleColumn .field {
    width: 100%;
    margin-bottom: 2.5rem;
  }
  .cart-proces #Form_Cart_ShippingCalendar_Holder {
    height: 37.5rem;
  }
}
@media screen and (max-width: 25rem) {
  .l-top-space {
    margin-top: 2.5rem;
  }
  .l-top-space--large {
    margin-top: 3.75rem;
  }
  .navigation__login-btn {
    padding: 0 1.875rem;
  }
  .navigation__login-btn img {
    margin: 0;
  }
  .navigation__login-btn span {
    display: none;
  }
  .navigation__title {
    font-size: 1.5rem;
  }
  .navigation__hidden-menu li {
    margin: 0.625rem 0;
  }
  .hidden-menu__link {
    font-size: 1.125rem;
  }
  .banner__image {
    padding-top: 85%;
  }
  .products__item {
    padding-bottom: 5.625rem;
  }
  .products__item .item__thumb img {
    max-width: 90%;
    height: auto;
  }
  .js-products__slider .slick-list {
    padding: 2.5rem 0 1.25rem;
  }
  .flatpickr-calendar {
    width: 100% !important;
  }
  .flatpickr-innerContainer {
    padding: 0.3125rem !important;
  }
}
@media screen and (max-width: 22.5rem) {
  #form-wrapper {
    width: 18.125rem;
    margin: 0 auto;
  }
  .flatpickr-rContainer {
    width: 17.5rem;
  }
  .flatpickr-days {
    width: 17.5rem;
  }
  .dayContainer {
    width: 17.5rem;
    min-width: 17.5rem;
  }
}