.js-cart-overview-hidden {
  visibility: hidden;
  clip-path: inset(0% 0% 95% 0% round 0 0 0 15%);
  @include transitionCubic(800ms);
}

#cart__btn.js-cart-toggle--active {
  background: $clr-alabaster !important;
}

.cart-summary {
  .js-cart-overview-open {
    visibility: visible !important;
    clip-path: inset(0% 0% 0% 0% round 0) !important;
  }
}
