.js-hidden-menu-container {
  visibility: hidden;
  clip-path: inset(0% 95% 95% 0% round 0 0 100px 0);
  @include transitionCubic(1000ms);
}

.js-hidden-menu-is-visible {
  visibility: visible;
  clip-path: inset(0% 0% 0% 0% round 0);
}

.js-hidden-menu-shop-style {
  background: transparent !important;
  color: $clr-white;

  svg path {
    fill: $clr-white;
  }
}

.js-btn-active {
  .icon-hamburger {
    transform: scale(0);
  }

  .icon-close {
    transform: scale(1);
  }
}

.js-cart-disabled {
  visibility: hidden !important;
  opacity: 0 !important;
  z-index: 0;

  .cart-overview__inner {
    display: none;
  }
}
