@media screen and (max-width: rem(560)) {
  .l-top-space--large {
    margin-top: rem(90);
  }

  .navigation__menu-btn {
    padding: 0 0 0 rem(80);

    span {
      display: none;
    }
  }

  #cart__btn {
    padding: 0 rem(30);
  }

  .cart-summary {
    #cart-overview {
      width: 100vw;
    }

    .cart-overview {
      width: 100vw;
    }
  }

  .banner__image {
    padding-top: 65%;
  }

  .banner__title {
    font-size: rem(26);
  }

  .banner__content {
    font-size: rem(16);
    line-height: rem(24);
    padding: rem(20);
  }

  .banner__links {
    margin: rem(30) 0 rem(40);

    a {
      margin-bottom: rem(20);
    }
  }

  .products__holder {
    padding: rem(40) 0 rem(20);
  }

  .products__item {
    .item__weight-option {
      display: inline-block;
      margin: rem(10) rem(10) rem(10) 0;
    }
  }

  .js-products__slider {
    .slick-list {
      padding: rem(40) 0;
    }
  }

  .selling-points__map {
    height: rem(400);
  }

  .selling-points__content {
    padding: rem(40) 5% rem(70);

    .content {
      width: 100%;
    }
  }

  .cart-proces {
    #Form_Cart_ShippingCalendar_Holder {
      height: rem(640);
    }

    fieldset {
      div.CompositeField {
        div.fieldgroup-field {
          width: 100%;
        }
      }
    }
  }

  .footer__section,
  .footer__section--main {
    width: 100%;
  }

  .footer__meta {
    .l-flex {
      align-items: center;
      flex-direction: column;

      span {
        margin: rem(5);
      }
    }
  }
}
