.navigation {
  position: fixed;
  background: $clr-white;
  display: flex;
  justify-content: space-between;
  z-index: 999;
}

.navigation__menu-btn {
  cursor: pointer;
  position: relative;
  background: $clr-stratos;
  display: flex;
  align-items: center;
  @include rubik(500, rem(16));
  color: $clr-white;
  border: none;
  padding: 0 rem(50) 0 rem(100);
  margin: 0;
  height: rem(90);
  @include borderRadius(0 0 6px 0);
  z-index: 999;

  .menu-btn__icon {
    position: absolute;
    @include transitionCubic(450ms);
  }

  .icon-hamburger {
    width: rem(30);
    height: auto;
    top: rem(35);
    left: rem(50);
  }

  .icon-close {
    width: rem(30);
    height: auto;
    top: rem(30);
    left: rem(50);
    transform: scale(0);
  }
}

.navigation__main-menu {
  list-style: none;
  padding: 0;
  margin-left: rem(40);

  li {
    display: inline;
    margin: 0 rem(10);
  }

  .main-menu__link {
    @include rubik(500, rem(16));
    color: $clr-stratos;
    display: inline-block;

    &:after {
      background: $clr-flamingo;
      display: block;
      content: " ";
      width: 0;
      height: 4px;
      margin-top: rem(5);
      @include transitionCubic(300ms);
    }

    &:hover:after {
      width: 80%;
    }
  }
}

.navigation__login-btn {
  background: $clr-flamingo;
  display: flex;
  align-items: center;
  @include rubik(500, rem(16));
  color: $clr-white;
  height: rem(90);
  padding: 0 rem(40);
  border: none;
  @include borderRadius(0 0 0 6px);

  img {
    width: rem(25);
    margin-right: rem(20);
  }
}

.navigation__overlay {
  position: fixed;
  background: $clr-stratos;
  color: $clr-white;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
}

.navigation__title {
  @include rubik(700, rem(40));
  text-transform: uppercase;
}

.navigation__hidden-menu {
  list-style: none;
  @include rubik(500, rem(20));
  padding: 0;

  li {
    margin: rem(20) 0;
  }

  .hidden-menu__link {
    @include transitionCubic(300ms);
    z-index: 9999;

    &:hover {
      color: $clr-flamingo;
    }
  }

  .current {
    color: $clr-flamingo;
  }
}

.navigation__submenu {
  @include rubik(400, rem(18));
  list-style: none;
  padding: 0 0 0 rem(20);

  li {
    margin: rem(15);
  }
}
