@media screen and (max-width: rem(1180)) {
  .navigation__main-menu {
    display: none;
  }

  .product {
    .btn {
      margin: rem(10) rem(10) rem(10) 0;
    }
  }

  .product__content {
    font-size: rem(16);
    line-height: rem(24);
  }

  .cart-proces {
    .form-wrapper__right {
      width: 40%;
    }
  }
}
