.selling-points {
  display: flex;
  flex: 1;
}

.selling-points__map,
.selling-points__content {
  width: 50%;
}

.selling-points__map {
  position: relative;
  @include borderRadius(0 50px 0 0);
  overflow: hidden;
}

#selling-point-map {
  display: block;
  width: 100%;
  height: 100%;
}

.selling-points__cta-link {
  background: $clr-white;
  position: absolute;
  display: inline-block;
  text-align: center;
  width: rem(320);
  bottom: 0;
  left: 50%;
  margin-left: rem(-160);
  padding: rem(20) 0;
  @include shadow(0, 0, 10px, 0.1);
  @include transitionCubic(300ms);

  .cta-link__title {
    @include rubik(700, rem(20));
    color: $clr-stratos;
  }

  .cta-link__text {
    @include karla(400, rem(14));
    margin-bottom: 0;
  }

  &:hover {
    padding: rem(20) 0 rem(50);
  }
}

.selling-points__content {
  padding: rem(50) 0 rem(80) rem(60);

  .content {
    width: 90%;
    max-width: rem(600);
  }
}

.selling-points__form {
  width: 90%;
  max-width: rem(400);

  input {
    margin-top: rem(20);
    max-width: rem(300);
  }
}

.selling-points__sub-title {
  @include rubik(700, rem(18));
  text-transform: uppercase;
  color: $clr-stratos;
}
