/*-- Basic styles --*/

html,
body {
  -ms-overflow-style: -ms-autohiding-scrollbar;
  position: relative;
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
}

* {
  box-sizing: border-box;
}

a {
  text-decoration: none;
  color: inherit;
  @include transition(300ms);
}

figure {
  margin: 0;
}

img {
  vertical-align: bottom;
}

textarea:focus,
input:focus,
button:focus {
  outline: none;
}

main {
  z-index: 3;
  position: relative;
}

input,
textarea {
  @include appearance(none);
  @include borderRadius(0);
}

input[type=checkbox] {
  @include appearance(checkbox);
}

#mc_embed_signup form {
  padding: 0 !important;
}
