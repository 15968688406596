.form {

  #Factuurgegevens .left,
  h3 {
    @include rubik(500, rem(24));
    color: $clr-stratos;
  }

  h4 {
    @include rubik(500, rem(16));
    color: $clr-stratos;
    margin-top: rem(20);
    margin-bottom: rem(30);
  }

  fieldset {
    border: none;
    padding: 0;

    legend {
      display: none;
    }
  }

  .field {
    display: block;
    margin: rem(20) 0 rem(35);

    label {
      margin-bottom: rem(10);
    }
  }

  label {
    display: inline-block;
    @include rubik(500, rem(16));
    color: $clr-shark;
    margin-bottom: rem(20);
  }

  .input-required-text {
    @include karla(400, rem(14));
    margin: 0 rem(5);
  }

  .input-required-indicator {
    @include karla(700, rem(18));
    color: $clr-flamingo;
  }

  .validation-message {
    display: block;
    @include karla(400, rem(14));
    color: $clr-flamingo;
    margin-top: rem(10);
  }

  .userformsgroup {

    .middleColumn {
      display: flex;
      justify-content: space-between;

      .field {
        width: 48%;
        margin: 0;
      }
    }
  }

  input[type=text],
  input[type=email],
  input[type=password],
  textarea,
  span.readonly {
    background: $clr-white;
    @include karla(400, rem(14));
    color: $clr-shark;
    padding: rem(15) rem(20);
    height: rem(50);
    width: 100%;
    border: none;
    @include borderRadius(6px);
    box-shadow: 0 0 20px 0 rgba(62, 106, 161, 0.2);
    border: 1px solid #E7E7E7;
  }

  span.readonly {
    display: block;
    width: 100%;
  }

  textarea {
    height: auto;
  }

  input[type=checkbox],
  input[type=checkbox]+label {
    display: inline;
  }

  input[type=submit] {
    cursor: pointer;
    display: inline-block;
    @include rubik(500, rem(14));
    @include borderRadius(6px);
    padding: rem(15) rem(20);
    border: 3px solid transparent;
    background: $clr-stratos;
    color: $clr-white;
    box-shadow: 0 0 20px 0 rgba(62, 106, 161, 0.2);
    @include transition(300ms);

    &:hover {
      background: transparent;
      border-color: $clr-stratos;
      color: $clr-stratos
    }
  }
}

.createAccount {
  max-width: rem(400);
  margin: 0 auto;
  text-align: left;
  padding-left: rem(40);

  .title {
    text-align: left;
  }

  label {
    display: block;
    font-weight: 700;
    margin-bottom: rem(30);
  }

  fieldset {
    div.field {
      width: 100% !important;
    }
  }
}
