@media screen and (max-width: rem(360)) {
  #form-wrapper {
    width: rem(290);
    margin: 0 auto;
  }

  .flatpickr-rContainer {
    width: rem(280);
  }

  .flatpickr-days {
    width: rem(280);
  }

  .dayContainer {
    width: rem(280);
    min-width: rem(280);
  }
}
