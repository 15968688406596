.shop-usp__list {
  list-style: none;
  padding: 0;
  display: flex;
  flex-wrap: wrap;
}

.shop-usp__usp {
  display: flex;
  align-items: center;
  @include karla(700, rem(14));
  color: $clr-stratos;
  margin: rem(20) rem(50) rem(20) 0;

  img {
    margin-right: rem(20);
    width: rem(36);
    height: auto;
  }
}
