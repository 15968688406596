.l-top-space {
  margin-top: em(70);
}

.l-top-space--small {
  margin-top: em(40);
}

.l-top-space--large {
  margin-top: em(140);
}

.l-top-space--banner {
  margin-top: em(180);
}

.l-top-space--negative {
  margin-top: -15%;
}

.l-bottom-space {
  margin-bottom: em(80);
}

.l-bottom-space--small {
  margin-bottom: em(40);
}
