.products {
  padding: rem(20) 0 rem(50);
}

.products__holder {
  padding: rem(80) 0;
}

.js-products__slider {
  position: relative;

  .slider__controls {
    cursor: pointer;
    position: absolute;
    display: block;
    background-color: transparent;
    background-repeat: no-repeat;
    top: 40%;
    height: rem(27);
    width: rem(14);
    border: none;
    z-index: 99;
    @include transition(300ms);
  }

  button[aria-disabled="true"] {
    opacity: 0;
  }

  .slider__prev {
    background-image: url("../images/icons/arrow-blue-left.svg");
    left: 0;
  }

  .slider__next {
    background-image: url("../images/icons/arrow-blue-right.svg");
    right: 0;
  }

  .slick-list {
    width: 90%;
    padding: rem(70) rem(40);
    margin: 0 auto;
  }

  .slick-slide {
    margin: 0 rem(20);
  }

  .slick-dots {
    list-style: none;
    position: absolute;
    display: flex;
    justify-content: center;
    bottom: rem(10);
    width: 100%;
    padding: 0;

    button {
      cursor: pointer;
      background: $clr-stratos;
      text-indent: -9999;
      display: block;
      width: rem(10);
      height: rem(10);
      padding: 0;
      overflow: hidden;
      @include borderRadius(50px);
      @include transitionCubic(300ms);
      margin: 0 rem(5);
      border: none;
    }

    .slick-active {
      button {
        width: rem(25);
      }
    }
  }
}

.products__type-holder {
  width: 48%;

  .products__item--main {
    display: none;
    width: 90%;
    min-height: rem(560);
  }

  .products__item--main.active {
    display: block;
  }
}

.js-cubes-slider {
  width: 50%;

  .slick-list,
  .slick-list div,
  .slick-list figure,
  .slick-track {
    height: 100%;
  }

  .slick-slide {
    padding: rem(35);
  }
}

.js-product-page-slider {
  position: relative;

  .slick-list,
  .slick-list div,
  .slick-list figure,
  .slick-track {
    height: 100%;
  }

  .slider__controls {
    cursor: pointer;
    position: absolute;
    display: block;
    background-color: transparent;
    background-repeat: no-repeat;
    background-size: 90%;
    background-position: center center;
    bottom: rem(15);
    height: rem(27);
    width: rem(14);
    border: none;
    z-index: 99;
    @include transition(300ms);
  }

  .slider__prev {
    background-image: url("../images/icons/arrow-blue-left.svg");
    left: rem(20);
  }

  .slider__next {
    background-image: url("../images/icons/arrow-blue-right.svg");
    right: rem(20);
  }

  .slick-dots {
    list-style: none;
    position: absolute;
    display: flex;
    justify-content: center;
    bottom: rem(10);
    width: 100%;
    padding: 0;

    button {
      cursor: pointer;
      background: $clr-stratos;
      text-indent: -9999;
      display: block;
      width: rem(10);
      height: rem(10);
      padding: 0;
      overflow: hidden;
      @include borderRadius(50px);
      @include transitionCubic(300ms);
      margin: 0 rem(5);
      border: none;
    }

    .slick-active {
      button {
        width: rem(25);
      }
    }
  }
}

.products__item {
  display: block;
  position: relative;
  background: $clr-white;
  color: $clr-stratos;
  padding: rem(30);
  max-width: rem(400);
  margin: 0 auto;
  @include borderRadius(6px);
  @include shadow(0, 0, 35px, 0.1);
  overflow: visible;

  .item__title {
    @include rubik(700, rem(20));
    text-transform: uppercase;
    margin: 0 0 rem(10);
  }

  .item__measurement {
    @include karla(400, rem(14));
  }

  .item__thumb {
    width: 100%;
    margin: rem(40) 0;

    img {
      display: block;
      width: auto;
      height: rem(140);
      margin: 0 auto;
    }
  }

  .item__weights {
    display: block;
    margin: rem(50) 0 rem(20);
  }

  .item__weight-option {
    cursor: pointer;
    margin-right: rem(10);
    margin-bottom: rem(10);
    display: inline-block;

    span {
      display: inline-block;
      background: $clr-white;
      @include karla(400, rem(16));
      color: $clr-stratos;
      padding: rem(15) rem(20);
      @include shadow(0, 0, 10px, 0.1);
      @include borderRadius(3px);
      border: 2px solid transparent;
      @include transition(300ms);
    }
  }

  .tablinks.active span {
    border-color: $clr-stratos;
  }

  .item__specs {
    @include karla(400, rem(16));
    line-height: rem(24);
  }

  .item__footer {
    padding: rem(10) 0 0;
    position: relative;
    margin-bottom: 30px;
    padding-bottom: 20px;
  }

  .item__price {
    @include rubik(700, rem(20));
  }

  .item__tax {
    @include karla(400, rem(14));
  }

  .item__add {
    cursor: pointer;
    position: absolute;
    background: $clr-stratos;
    @include rubik(500, rem(32));
    color: $clr-white;
    padding: rem(10) rem(30);
    @include borderRadius(6px 0 0 0);
    right: 0;
    bottom: 0;
    border: none;
  }
}

.products__item--main {
  width: 45%;
  max-width: initial;
}

.products__item--small {
  max-width: rem(350);
}

.product-info {
  background-color: $clr-alabaster;
}

.product-info__image {
  width: 60%;

  img {
    width: 60%;
    margin: 0 auto;
    transform: scale(1.2);
  }
}

.product-info__content {
  width: 40%;
}

.product {
  flex: 1;
  height: 100%;

  .btn {
    margin-right: rem(20);
  }
}

.product__image {
  display: flex !important;
  justify-content: center;
  align-items: center;
  background: $clr-alabaster;
  @include borderRadius(0 50px 0 0);
  overflow: hidden;

  img {
    width: rem(450);
    max-width: 90%;
  }
}

.product__content {
  @include karla(400, rem(16));
  line-height: rem(24);
  color: $clr-stratos;
  padding: rem(40) rem(60);
}

.product__weights {
  display: flex;
  list-style: none;
  padding: 0;
  margin: rem(40) 0;
}

.product__weight-option {
  display: block;
  background: $clr-white;
  @include karla(400, rem(16));
  color: $clr-stratos;
  padding: rem(15) rem(20);
  margin-right: rem(20);
  @include shadow(0, 0, 10px, 0.1);
  @include borderRadius(3px);
  border: 2px solid transparent;
  @include transition(300ms);

  &:hover {
    border: 2px solid $clr-stratos;
  }
}

.product__weight-option.active {
  border-color: $clr-stratos;
}

.product__price {
  display: inline-block;
  @include rubik(700, rem(20));
  margin-right: rem(20);
}

.info__staffel{
  margin-left: 5px;
  border-radius: 50%;
  font-size: 20px;
  color: #fff;
  text-align: center;
  background: $clr-stratos;
  cursor: pointer;
  padding-left: 10px;
  padding-right: 10px;
}

.staffel__discount{
  border-radius: 6px;
  table tr td{
    text-align: center;
  }
  font-family: 'Rubik';
  font-style: normal;
  font-size: 16px;
  color: white;
  width: 100%;
  padding: 10px 5px;
  margin-top: 15px;
  cursor: auto;
  z-index: 10;
  background-color: #010b40;
  border: 1px solid black;

  table{
    width: 100%;
    border-collapse: collapse;
    td, th{
      padding: 3px;
    }
  }

  &.variation{
    width: 350px;
  }

}

