/*-- Regular Mixins --*/

@mixin transition($ms) {
  -webkit-transition: all $ms ease-in-out;
  -moz-transition: all $ms ease-in-out;
  -o-transition: all $ms ease-in-out;
  transition: all $ms ease-in-out;
}

@mixin transitionCubic($ms) {
  -webkit-transition: all $ms cubic-bezier(0.175, 0.885, 0.32, 1.275);
  -moz-transition: all $ms cubic-bezier(0.175, 0.885, 0.32, 1.275);
  -o-transition: all $ms cubic-bezier(0.175, 0.885, 0.32, 1.275);
  transition: all $ms cubic-bezier(0.175, 0.885, 0.32, 1.275);
}

@mixin transform($transform) {
  -ms-transform: $transform;
  -webkit-transform: $transform;
  transform: $transform;
}

@mixin borderRadius($px) {
  -webkit-border-radius: $px;
  -moz-border-radius: $px;
  border-radius: $px;
}

@mixin shadow($horizontal, $vertical, $blur, $opacity) {
  -webkit-box-shadow: $horizontal $vertical $blur 0px rgba(0, 0, 0, $opacity);
  -moz-box-shadow: $horizontal $vertical $blur 0px rgba(0, 0, 0, $opacity);
  box-shadow: $horizontal $vertical $blur 0px rgba(0, 0, 0, $opacity);
}

@mixin collumCount($count) {
  -webkit-columns: auto $count;
  -moz-columns: auto $count;
  columns: auto $count;
}

@mixin collumGap($gap) {
  -webkit-column-gap: $gap;
  -moz-column-gap: $gap;
  column-gap: $gap;
}

@mixin appearance($value) {
  -webkit-appearance: $value;
  -moz-appearance: $value;
  appearance: $value;
}

@mixin backgroundSize($size) {
  -webkit-background-size: $size !important;
  -moz-background-size: $size !important;
  -o-background-size: $size !important;
  background-size: $size !important;
}
