.js-cta-popup {
  visibility: hidden;
  opacity: 0;
  @include transition(300ms);
}

.js-cta-popup-show {
  visibility: visible;
  opacity: 1;
}
