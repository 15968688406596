.l-columns {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.l-columns--baseline {
  align-items: baseline;
}

.l-column__gutter-bottom {
  margin-bottom: 4%;
}

.l-column__col2 {
  width: 48%;
}

.l-column__col3 {
  width: 31%;
}

.l-column__col4 {
  width: 24%;
}

.l-column__col3--large {
  width: 32%;
}
