@media screen and (max-width: rem(760)) {

  .l-flex__c60,
  .l-flex__c50,
  .l-flex__c40 {
    width: 100%;
  }

  .container {
    padding-top: rem(70);
  }

  .login__half {
    width: 100%;
  }

  .navigation__menu-btn,
  .navigation__login-btn,
  #cart__btn {
    font-size: rem(14);
    height: rem(70);
  }

  .navigation__menu-btn {
    padding: 0 rem(35) 0 rem(80);

    .icon-hamburger {
      width: rem(20);
      top: rem(30);
      left: rem(30);
    }

    .icon-close {
      width: rem(20);
      top: rem(25);
      left: rem(30);
    }
  }

  .navigation__login-btn {
    img {
      width: rem(20);
    }
  }

  .content__half-divider {
    width: 100%;
  }

  .content__text {
    order: 1;
  }

  .content__image {
    order: 2 !important;
    margin: rem(50) 0 0;
  }

  .cart__btn {

    img,
    svg {
      width: rem(25);
      height: auto;
    }
  }

  .cart-summary {
    #cart-overview {
      top: rem(70);
      height: calc(100vh - 70px);
    }
  }

  .cart__mobile {
    display: block;
  }

  .cart__delivery-form {
    padding-left: 0;
    margin-top: rem(40);
  }

  .cart-proces {
    #Form_Cart_ShippingCalendar_Holder {
      height: rem(620);
    }
  }

  .btn,
  .form input[type=submit] {
    padding: rem(10) rem(20);
  }

  .title--sub {
    font-size: rem(22);
  }

  .createAccount {
    max-width: initial;
    width: 100%;
    margin: rem(50) 0 0 0;
    padding-left: 0;
  }
}
