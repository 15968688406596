@media screen and (max-width: rem(470)) {
  .banner__image {
    padding-top: 75%;
  }

  .content--section {
    padding: rem(30) 0;
  }

  .form {
    fieldset.field {
      margin-bottom: 0;
    }

    .userformsgroup {
      .middleColumn {
        flex-wrap: wrap;

        .field {
          width: 100%;
          margin-bottom: rem(40);
        }
      }
    }
  }

  .cart-proces {
    #Form_Cart_ShippingCalendar_Holder {
      height: rem(600);
    }
  }
}
