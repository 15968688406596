.container {
  display: flex;
  flex-direction: column;
  padding-top: rem(90);
  flex: 1;
}

.bg-cover {
  @include backgroundSize(cover);
  background-position: center center;
  background-repeat: no-repeat;
}

.title {
  text-align: center;
  margin: rem(15) 0;
}

.title--left {
  text-align: left;
}

.title--main {
  @include karla(700, rem(18));
  color: $clr-flamingo;
}

.title--sub {
  @include rubik(700, rem(26));
  color: $clr-stratos;
  text-transform: uppercase;
  line-height: rem(32);
  margin: rem(10) 0;
}
