/* rubik-regular - latin */
@font-face {
  font-family: 'Rubik';
  font-style: normal;
  font-weight: 400;
  src: url('../fonts/rubik/rubik-v9-latin-regular.eot');
  /* IE9 Compat Modes */
  src: local('Rubik'), local('Rubik-Regular'),
    url('../fonts/rubik/rubik-v9-latin-regular.eot?#iefix') format('embedded-opentype'),
    /* IE6-IE8 */
    url('../fonts/rubik/rubik-v9-latin-regular.woff2') format('woff2'),
    /* Super Modern Browsers */
    url('../fonts/rubik/rubik-v9-latin-regular.woff') format('woff'),
    /* Modern Browsers */
    url('../fonts/rubik/rubik-v9-latin-regular.ttf') format('truetype'),
    /* Safari, Android, iOS */
    url('../fonts/rubik/rubik-v9-latin-regular.svg#Rubik') format('svg');
  /* Legacy iOS */
  font-display: swap;
}

/* rubik-500 - latin */
@font-face {
  font-family: 'Rubik';
  font-style: normal;
  font-weight: 500;
  src: url('../fonts/rubik-v9-latin-500.eot');
  /* IE9 Compat Modes */
  src: local('Rubik Medium'), local('Rubik-Medium'),
    url('../fonts/rubik/rubik-v9-latin-500.eot?#iefix') format('embedded-opentype'),
    /* IE6-IE8 */
    url('../fonts/rubik/rubik-v9-latin-500.woff2') format('woff2'),
    /* Super Modern Browsers */
    url('../fonts/rubik/rubik-v9-latin-500.woff') format('woff'),
    /* Modern Browsers */
    url('../fonts/rubik/rubik-v9-latin-500.ttf') format('truetype'),
    /* Safari, Android, iOS */
    url('../fonts/rubik/rubik-v9-latin-500.svg#Rubik') format('svg');
  /* Legacy iOS */
  font-display: swap;
}

/* rubik-700 - latin */
@font-face {
  font-family: 'Rubik';
  font-style: normal;
  font-weight: 700;
  src: url('../fonts/rubik-v9-latin-700.eot');
  /* IE9 Compat Modes */
  src: local('Rubik Bold'), local('Rubik-Bold'),
    url('../fonts/rubik/rubik-v9-latin-700.eot?#iefix') format('embedded-opentype'),
    /* IE6-IE8 */
    url('../fonts/rubik/rubik-v9-latin-700.woff2') format('woff2'),
    /* Super Modern Browsers */
    url('../fonts/rubik/rubik-v9-latin-700.woff') format('woff'),
    /* Modern Browsers */
    url('../fonts/rubik/rubik-v9-latin-700.ttf') format('truetype'),
    /* Safari, Android, iOS */
    url('../fonts/rubik/rubik-v9-latin-700.svg#Rubik') format('svg');
  /* Legacy iOS */
  font-display: swap;
}

/* karla-regular - latin */
@font-face {
  font-family: 'Karla';
  font-style: normal;
  font-weight: 400;
  src: url('../fonts/karla/karla-v13-latin-regular.eot');
  /* IE9 Compat Modes */
  src: local('Karla'), local('Karla-Regular'),
    url('../fonts/karla/karla-v13-latin-regular.eot?#iefix') format('embedded-opentype'),
    /* IE6-IE8 */
    url('../fonts/karla/karla-v13-latin-regular.woff2') format('woff2'),
    /* Super Modern Browsers */
    url('../fonts/karla/karla-v13-latin-regular.woff') format('woff'),
    /* Modern Browsers */
    url('../fonts/karla/karla-v13-latin-regular.ttf') format('truetype'),
    /* Safari, Android, iOS */
    url('../fonts/karla/karla-v13-latin-regular.svg#Karla') format('svg');
  /* Legacy iOS */
  font-display: swap;
}

/* karla-700 - latin */
@font-face {
  font-family: 'Karla';
  font-style: normal;
  font-weight: 700;
  src: url('../fonts/karla-v13-latin-700.eot');
  /* IE9 Compat Modes */
  src: local('Karla Bold'), local('Karla-Bold'),
    url('../fonts/karla/karla-v13-latin-700.eot?#iefix') format('embedded-opentype'),
    /* IE6-IE8 */
    url('../fonts/karla/karla-v13-latin-700.woff2') format('woff2'),
    /* Super Modern Browsers */
    url('../fonts/karla/karla-v13-latin-700.woff') format('woff'),
    /* Modern Browsers */
    url('../fonts/karla/karla-v13-latin-700.ttf') format('truetype'),
    /* Safari, Android, iOS */
    url('../fonts/karla/karla-v13-latin-700.svg#Karla') format('svg');
  /* Legacy iOS */
  font-display: swap;
}

/* Fonts styles */
@mixin rubik($weight, $size) {
  font-family: 'Rubik', sans-serif;
  font-weight: $weight;
  font-size: $size;
}

@mixin karla($weight, $size) {
  font-family: 'Karla', sans-serif;
  font-weight: $weight;
  font-size: $size;
}
