@media screen and (max-width: rem(660)) {

  .content__title-divider,
  .content__text-divider {
    width: 100%;
  }

  .content--top-padding {
    padding-top: 0;
  }

  .content--section {
    padding: rem(40) 0;
  }

  .content__cta {

    .cta__content,
    .cta__image {
      width: 100%;
    }

    .cta__image {
      order: 1;
    }

    .cta__content {
      order: 2;
    }
  }

  .cart-proces {
    .cart__product {
      img {
        display: none;
      }
    }
  }
}
