body.CartStep2 {
  .container {
    background: $clr-alabaster;
  }
}

.cart-proces {

  .form+div .cart-proces__total {
    display: none;
  }

  #form-wrapper {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
  }

  .form-wrapper__left {
    width: 50%;
  }

  .form-wrapper__right {
    width: 35%;
  }

  .shippingCalanderTitle {
    margin-top: rem(60)
  }

  #Form_Cart_ShippingCalendar_Holder {
    width: 100%;
    margin-top: rem(20);

    #Form_Cart_ShippingCalendar {
      max-width: rem(350);
    }
  }

  #deliveryTimeSlotGroup,
  #Form_Cart_paymentMethod_Holder {
    .fieldholder-small-label {
      @include rubik(500, rem(18));
      color: $clr-stratos;
    }

    .fieldgroup-field {
      width: 100%;
    }
  }

  #Form_Cart_deliveryTimeSlot_Holder {
    .optionset {
      margin-top: rem(35);

      li {
        display: flex;
      }
    }

    .validation-message {
      position: absolute;
      top: rem(-40);
      left: 0;
    }
  }

  #Form_Cart_ShippingCountry_Holder,
  #Form_Cart_BillingCountry_Holder {
    display: none;
  }

  #CallMeGroup,
  #Form_Cart_deliveryTimeSlot_Holder {
    margin-top: rem(20);
  }

  #CallMeGroup,
  #Form_Cart_OneAddressForEverything_Holder {
    label {
      color: $clr-shark;
    }
  }

  #OtherPersonFields {
    border-bottom: solid 1px #c3c3c3;
  }

  #Form_Cart_OneAddressForEverything_Holder {
    width: 100%;
    margin-top: 0;
    margin-bottom: rem(20);
  }

  #Form_Cart_OrderRemark_Holder {
    margin-bottom: rem(60);
  }

  #Verzendgegevens {
    label.left {
      display: none;
    }
  }

  #Form_Cart_paymentMethod_ideal+label {
    &:after {
      content: " ";
      display: block;
      background-image: url('../images/icons/ideal.svg');
      background-repeat: no-repeat;
      background-position: center;
      background-size: 90%;
      width: rem(50);
      height: rem(50);
      margin-left: rem(20);
    }
  }

  #Form_Cart_paymentMethod_creditcard+label {
    &:after {
      content: " ";
      display: block;
      background-image: url('../images/icons/mastercard.svg');
      background-repeat: no-repeat;
      background-position: center;
      background-size: 90%;
      width: rem(50);
      height: rem(50);
      margin-left: rem(20);
    }
  }

  #Form_Cart_Same {
    li.val2 {
      input+label:after {
        content: " ";
        background: url("../images/icons/arrow-down-indicator.svg") no-repeat;
        background-size: 100%;
        display: block;
        width: rem(12);
        height: rem(9);
        margin: 2px 0 0 15px;
        transform: rotate(-90deg);
        @include transitionCubic(300ms);
      }

      input:checked+label:after {
        transform: rotate(0deg);
      }
    }
  }

  #Contactpersoon {
    .validation-message {
      position: absolute;
      top: rem(-30);
      left: 0;
    }

    div.fieldgroup-field {
      width: 100%;
      margin-bottom: 0;
    }
  }

  fieldset {
    >div.field {
      display: inline-block;
      width: 46%;
      margin: 0 2% rem(20) 0;
    }

    >div.field.dropdown {
      width: 100%;
    }

    .optionset {
      width: 100%;
      margin-top: 0;

      label {
        color: $clr-shark;
      }
    }

    div.CompositeField {
      width: 100%;

      >label {
        @include rubik(500, rem(16));
        color: $clr-stratos;
        margin-top: rem(20);
        margin-bottom: rem(30);
      }

      div.fieldgroup {
        display: flex;
        justify-content: space-between;
        align-items: baseline;
        flex-wrap: wrap;
      }

      div.fieldgroup-field {
        width: 48%;
        margin-bottom: rem(25);
      }
    }
  }

  .optionset {
    position: relative;
    list-style: none;
    padding: 0;
    margin-top: 0;
    margin-bottom: 0;
  }

  input+small {
    display: block;
    @include rubik(400, rem(14));
    color: $clr-flamingo;
    margin-top: rem(15);
  }

  .title {
    text-align: left;
  }
}

.cart-proces__title {
  @include rubik(500, rem(24));
  color: $clr-stratos;
}

.calander-legend {
  display: block;
  list-style: none;
  padding: 0;
  margin-top: rem(40);
}

.calander-legend__item {
  display: flex;
  align-items: center;
  @include karla(400, rem(14));
  color: $clr-stratos;
  margin: rem(10) 0;

  p {
    margin: 0;
  }
}

.calander-legend__color {
  display: block;
  width: rem(20);
  min-width: rem(20);
  height: rem(20);
  margin-right: rem(10);
  @include borderRadius(3px);
}

.calander-legend__color--green {
  background: #8dc63f;
}

.calander-legend__color--orange {
  background: #fe7709;
}

.calander-legend__color--red {
  background: #be0000;
}

.cart-proces__total {
  @include rubik(500, rem(16));
  line-height: rem(46);
  color: $clr-stratos;
  width: 100%;
  max-width: rem(550);

  hr {
    margin: rem(20) 0;
  }

  .total__prop {
    display: block;
    width: 50%;
  }

  .total__price {
    display: block;
    width: 50%;
    font-size: rem(20);
  }

  .total__delivery {
    display: block;
    width: 50%;
  }
}

.flatpickr-calendar.open {
  z-index: 10 !important;
}

.flatpickr-calendar {
  position: relative;
  display: block;
  @include karla(400, rem(14));
  border: none;
  width: rem(350);
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1) !important;
  top: 0 !important;
  left: 0 !important;
  visibility: visible !important;
  opacity: 1 !important;
  margin-top: rem(20);

  .flatpickr-months,
  .flatpickr-current-month {
    @include rubik(400, rem(16));
    color: $clr-stratos;
    height: rem(60);
    padding-top: rem(10);
  }

  .flatpickr-next-month,
  .flatpickr-prev-month {
    top: rem(10) !important;
  }

  .flatpickr-weekday {
    @include rubik(700, rem(14));
    color: $clr-stratos;
  }

  .flatpickr-day {
    color: #8dc63f;

    &:hover {
      background: $clr-stratos;
      color: $clr-white;
      border-color: $clr-stratos;
    }
  }

  .flatpickr-day.flatpickr-disabled,
  .flatpickr-day.redDay,
  .flatpickr-day.prevMonthDay {
    color: #be0000;
    background: transparent;
    border: none;
  }

  .flatpickr-day.orangeDay {
    color: #fe7709;
  }

  .flatpickr-day.today {
    background: #eeeeee;
    color: #be0000;
    font-weight: 700;
    border: none;
    @include borderRadius(10px);
  }

  .flatpickr-day.nextMonthDay,
  .flatpickr-day.prevMonthDay {
    opacity: 0;

    &:hover {
      background: $clr-stratos;
      color: $clr-white;
    }
  }

  .flatpickr-day.selected {
    background: $clr-stratos;
    border: none;
    color: $clr-white;

    &:hover {
      background: $clr-flamingo;
    }
  }

  .flatpickr-innerContainer {
    padding: rem(20);
  }
}

.flatpickr-months .flatpickr-month {
  height: rem(60) !important;
}
