.cta-calculating__content {
  @include karla(400, rem(16));
  color: $clr-stratos;
  line-height: rem(24);
  width: 35%;
}

.cta-calculating__image {
  width: 65%;

  img {
    width: 100%;
    height: auto;
  }
}
