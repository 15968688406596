.content {
  @include karla(400, rem(16));
  color: $clr-stratos;
  line-height: rem(24);

  h2 {
    @include rubik(700, rem(28));
    line-height: rem(34);
  }

  .cubes-illustration {
    width: 100%;
    max-width: rem(220);
    margin: 0 auto rem(60);

    img {
      width: 100%;
      height: auto;
    }
  }

  .color-orange {
    color: $clr-flamingo;
  }

  .color-white {
    color: $clr-white;
  }

  .text-right {
    text-align: right;
  }
}

.content__intro {
  @include karla(400, rem(26));
  line-height: rem(38);
}

.content.links {
  .content__text {
    order: 2;
  }

  .content__image {
    order: 1;
  }
}

.content.rechts {
  .content__text {
    order: 1;
  }

  .content__image {
    order: 2;
  }
}

.content--center {
  text-align: center;
}

.content--section {
  padding: rem(60) 0;
}

.content--bg-white {
  background: $clr-white;
}

.content--bg-gray {
  background: $clr-alabaster;
}

.content--top-padding {
  padding-top: rem(40);
}

.content__title-divider {
  width: 35%;
}

.content__text-divider {
  width: 65%;
}

.content__half-divider {
  width: 45%;
}

.content__image {
  img {
    display: block;
    max-width: 85%;
    height: auto;
    margin: 0 auto;
  }
}

.content__cta {

  .cta__content,
  .cta__image {
    width: 46%;

    img {
      width: 100%;
      height: auto;
    }
  }
}
