.deliverycosts-calculating {
  .form {
    input[type=text] {
      display: inline-block;
      @include karla(400, rem(16));
      width: rem(240);
    }

    input[type=submit] {
      background-color: $clr-stratos;
      background-image: url('../images/icons/arrow-right-white.svg');
      background-repeat: no-repeat;
      background-position: center;
      background-size: rem(10);
      display: inline-block;
      width: rem(50);
      height: rem(50);
      margin-left: rem(-10);
      border: none;
      @include borderRadius(0 3px 3px 0);
    }
  }
}

#deliverycostform {
  position: relative;
}

.deliverycosts-calculating__form {
  position: relative;
}

.deliverycosts__popup {
  position: absolute;
  top: rem(80);
  display: block;
  @include karla(700, rem(14));
  color: $clr-white;
  background: $clr-stratos;
  padding: rem(15) rem(40);
  @include borderRadius(6px);
  @include shadow(0, 0, 10px, 0.1);

  a {
    color: $clr-flamingo;

    &:hover {
      text-decoration: underline;
    }
  }

  strong {
    @include rubik(700, rem(16));
  }

  &:before {
    content: " ";
    position: absolute;
    top: -20px;
    left: 20%;
    margin-left: -20px;
    border-width: 20px;
    border-style: solid;
    border-color: transparent transparent transparent $clr-stratos;
  }
}
