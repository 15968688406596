@media screen and (max-width: rem(400)) {
  .l-top-space {
    margin-top: rem(40);
  }

  .l-top-space--large {
    margin-top: rem(60);
  }

  .navigation__login-btn {
    padding: 0 rem(30);

    img {
      margin: 0;
    }

    span {
      display: none;
    }
  }

  .navigation__title {
    font-size: rem(24);
  }

  .navigation__hidden-menu {
    li {
      margin: rem(10) 0;
    }
  }

  .hidden-menu__link {
    font-size: rem(18);
  }

  .banner__image {
    padding-top: 85%;
  }

  .products__item {
    padding-bottom: rem(90);

    .item__thumb {
      img {
        max-width: 90%;
        height: auto;
      }
    }
  }

  .js-products__slider {
    .slick-list {
      padding: rem(40) 0 rem(20);
    }
  }

  .flatpickr-calendar {
    width: 100% !important;
  }

  .flatpickr-innerContainer {
    padding: rem(5) !important;
  }
}
