.l-flex {
  display: flex;
  flex-wrap: wrap;
}

.l-flex--container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 100vh;
}

.l-flex--between {
  justify-content: space-between;
}

.l-flex--center {
  justify-content: center;
}

.l-flex--align-center {
  align-items: center;
}

.l-flex__c30 {
  width: 30%;
}

.l-flex__c40 {
  width: 40%;
}

.l-flex__c50 {
  width: 50%;
}

.l-flex__c60 {
  width: 60%;
}
